import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class SetExplicitTaskTypeGQL extends Mutation {
  document = gql`
  mutation SetExplicitTaskTypeGQL(
    $id: uuid!,
    $is_implicit: Boolean!,
    $is_expanded: Boolean!
  ) {
    update_tasks_by_pk(
      pk_columns: {id: $id},
      _set: {
        is_implicit: $is_implicit,
        is_expanded: $is_expanded
      }) {
      id
    }
  }
  `;
}
