import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleCalendarSettingsComponent } from './components/google-calendar-settings/google-calendar-settings.component';
import { EwsSettingsComponent } from './components/ews-settings/ews-settings.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ObjectsModule } from '../../core/components/objects/objects.module';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    GoogleCalendarSettingsComponent,
    EwsSettingsComponent,
  ],
    imports: [
        CommonModule,
        MatSlideToggleModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatCheckboxModule,
        ObjectsModule,
        SharedModule,
        TranslateModule,
    ],
  exports: [
    GoogleCalendarSettingsComponent,
    EwsSettingsComponent,
  ],
})
export class GoogleFeaturesModule { }
