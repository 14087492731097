<div class="c-dialog">
  <div class="c-dialog__body">
    <p class="c-dialog__title c-dialog__title--smallMargin">{{'Confirm your actions' | translate}}</p>
    <p class="c-dialog__text text-center">{{message | translate}}</p>
  </div>
  <div class="c-dialog__footer">
    <div class="c-dialog__footerBtns">
      <app-button btnStyle="outline" matDialogClose>{{cancelButtonText | translate}}</app-button>
      <app-button (buttonClicked)="onConfirmClick()">{{confirmButtonText | translate}}</app-button>
    </div>
  </div>
</div>
