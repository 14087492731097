import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Template } from 'src/app/core/models/interfaces';

@Component({
  selector: 'edit-template-dialog',
  templateUrl: './edit-template-dialog.html',
})
export class EditTemplateDialog implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditTemplateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { template: Template },
  ) {
  }

  ngOnInit(): void {
    if (this.data.template !== undefined) {
      this.form = this.formBuilder.group({
        templateName: [this.data.template.name, Validators.required],
        templateActive: [this.data.template.active],
      });
    } else {
      this.form = this.formBuilder.group({
        templateName: ['', Validators.required],
        templateActive: [true],
      });
    }
  }

  onSubmit() {
    if (this.form.valid) {
      if (this.data.template !== undefined) {
        this.dialogRef.close({
          id: this.data.template.id,
          name: this.form.value.templateName,
          active: this.form.value.templateActive,
        });
      } else {
        this.dialogRef.close({
          name: this.form.value.templateName,
          active: this.form.value.templateActive,
        });
      }
    }
  }
}
