import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject, throwError } from 'rxjs';
import { LoginRequest } from 'src/app/core/models/loginRequest';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { Token } from 'src/app/core/models/token';
import { NotificationService } from 'src/app/core/services/notification.service';
import { MLS_IN_SECOND, NO_CONTENT_HTTP_CODE, SEC_IN_MINUTE } from '../../../_helpers/constants.helper';
import { UpdateUserDisplayNameGQL } from '../../../core/services/graphql-queries/updateUserDisplayNameGQL';
import { CookieService } from 'ngx-cookie';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

interface JwtDataInterface {
  jwt_expires_in: number;
  jwt_token: string;
  refresh_token: string;
  user?: {
    display_name: string;
    email: string;
    id: string;
  };
}

@Injectable({providedIn: 'root'})
export class AuthService {

  public readonly INITIAL_PATH = '/app/day-editor';

  private readonly JWT_TOKEN = 'JWT_TOKEN';

  public logout$: Subject<boolean> = new Subject<boolean>();
  public login$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private cookie: CookieService,
    private translate: TranslateService,
    private updateUserDisplayNameGQL: UpdateUserDisplayNameGQL,
    private notificationService: NotificationService) {
  }

  isLoggedIn$(): Observable<boolean> {
    return of(this.cookie.hasKey(this.JWT_TOKEN));
  }

  public getToken(): JwtDataInterface {
    return JSON.parse(this.cookie.get(this.JWT_TOKEN) || '{}');
  }

  public get getUser() {
    return this.getToken().user;
  }

  login(loginRequest: LoginRequest): Observable<any> {
    return this.http.post('/auth/login', loginRequest)
      .pipe(tap((data) => {
        this.setTokenAndStartRefresh(data, true);
      }));
  }

  logout() {
    const options = {params: new HttpParams().set('refresh_token', this.getToken().refresh_token)};

    return this.http.post('/auth/logout', {}, options)
      .pipe(
        tap(() => {
            this.clearAuthStorages();
          },
          catchError((error) => {
            this.clearAuthStorages();
            return throwError(error);
          }),
        ));
  }

  clearAuthStorages() {
    this.stopRefreshTokenTimer();
    this.cookie.remove(this.JWT_TOKEN);
    this.logout$.next(null);
    this.router.navigate(['/login']).then();
  }

  refreshToken() {
    if (!this.getToken().refresh_token) {
      return of(null);
    }
    const options = {params: new HttpParams().set('refresh_token', this.getToken().refresh_token)};
    return this.http.get<JwtDataInterface>('/auth/token/refresh', options)
      .pipe(
        filter((res) => {
          const tokenExist = !!res && !!res.jwt_token;
          if (!tokenExist) {
            this.clearAuthStorages();
          }
          return tokenExist;
        }),
        tap((data) => this.setTokenAndStartRefresh(data)),
        catchError((err) => {
          this.clearAuthStorages();
          this.notificationService.warning({
            description: this.translate.instant(marker('Your login session has expired. Please login again')),
          });
          return throwError(err);
        }),
      );
  }

  changePassword(old_password: string, new_password: string) {
    return this.http.post('/auth/change-password',
      {old_password, new_password}, {observe: 'response'});
  }

  changePasswordWithToken(token: string, password: string) {
    return this.http.post('/auth/change-password/change',
      {'ticket': token, 'new_password': password}, {observe: 'response'});
  }

  changePasswordRequest(email: string) {
    return this.http.post('/auth/change-password/request', {'email': email}, {observe: 'response'});
  }

  changeEmail(newEmail: string) {
    return this.http.post('/auth/change-email', {'new_email': newEmail}, {observe: 'response'});
  }

  changeName(id: string, display_name: string) {
    return this.updateUserDisplayNameGQL.mutate({id, display_name});
  }

  private refreshTokenTimeout: ReturnType<typeof setTimeout>;

  private startRefreshTokenTimer() {
    // set a timeout to refresh the token a minute before it expires
    const timeout = this.getToken().jwt_expires_in - SEC_IN_MINUTE * 1 * MLS_IN_SECOND;
    this.refreshTokenTimeout = setTimeout(
      () => {
        const refreshTokenSub = this.refreshToken().pipe(take(1)).subscribe(() => refreshTokenSub.unsubscribe());
      }, timeout);
  }

  public stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  public register(registerRequest): Observable<any> {
    return this.http.post('/auth/register', registerRequest)
      .pipe(tap((data) => this.setTokenAndStartRefresh(data)));
  }

  private setTokenAndStartRefresh(data, isLoggedIn: boolean = false) {
    const token = new Token(data);
    this.cookie.put(this.JWT_TOKEN, JSON.stringify(token.jwt));
    this.startRefreshTokenTimer();
    if (!!isLoggedIn) {
      this.login$.next(null);
    }
  }

  public sendNotificationWhen204Code(response, msg: string) {
    if (response.status === NO_CONTENT_HTTP_CODE) {
      this.notificationService.success({
        description: msg,
      });
    }
  }
}
