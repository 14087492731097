import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  ActivityStatus,
  ChangeTaskStatusActivity,
  PlanningBoardTask,
  PriorityID,
  sortStep,
  UUID,
} from '../planning-board.helper';
import { NotificationService } from '../../../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Activity } from '../../../../../core/models/interfaces';
import { GenerateDefaultActivityForTaskGQL } from '../graphql/generateDefaultActivityForTaskGQL';
import { finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import { FetchAllGroupContainersGQL } from '../graphql/fetchAllClipboardActivitiesGQL';
import { TasksService } from '../tasks.service';
import { NgProgressService } from '../../../../../shared/services/ng-progress.service';
import { ActivitiesService } from '../activities.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { deepClone } from '../../../../../_helpers/helpers';
import { BigInt } from '../group-containers.service';
import { DeleteActivityByPkGQL } from '../../../../../core/services/graphql-queries/deleteActivityByPkGQL';

export const CLIPBOARD_VISIBILITY_STORAGE_KEY = 'clipboard-visible';

@Injectable()
export class PlanningBoardClipboardService {
  private _clipboardActivities$: BehaviorSubject<Activity[]> = new BehaviorSubject<Activity[]>([]);
  private _clipboardVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(!!localStorage.getItem(CLIPBOARD_VISIBILITY_STORAGE_KEY));
  public newActivityForTask$: BehaviorSubject<Activity> = new BehaviorSubject<Activity>(null);

  constructor(
    private deleteActivityByPkGQL: DeleteActivityByPkGQL,
    private tasksService: TasksService,
    private progressService: NgProgressService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private activitiesService: ActivitiesService,
    private fetchAllGroupContainersGQL: FetchAllGroupContainersGQL,
    private generateDefaultActivityForTaskGQL: GenerateDefaultActivityForTaskGQL,
  ) {
  }

  get clipboardVisible$(): BehaviorSubject<boolean> {
    return this._clipboardVisible$;
  }

  public toggleVisibility(visibility: boolean) {
    if (!!visibility) {
      localStorage.setItem(CLIPBOARD_VISIBILITY_STORAGE_KEY, 'true');
    } else {
      localStorage.removeItem(CLIPBOARD_VISIBILITY_STORAGE_KEY);
    }
    this._clipboardVisible$.next(visibility);
  }

  setClipboardActivities(activities: Activity[]) {
    this._clipboardActivities$.next(activities);
  }

  // TODO: is this somehwere used anymore?
  pushClipboardActivity(activity: Activity) {
    const clipboardActivities = this._clipboardActivities$.getValue();

    if (clipboardActivities.some((el) => el.id === activity.id)) {
      return;
    }

    let activities = [...clipboardActivities, activity];
    activities = activities.sort((a, b) => a.order_in_clipboard - b.order_in_clipboard);
    this._clipboardActivities$.next(activities);
  }

  getAllClipboardActivities() {
    return this.fetchAllGroupContainersGQL.watch({}, {fetchPolicy: 'no-cache'}).valueChanges;
  }

  updateClipboardActivity(activity: Activity) {
    const clipboardActivities = this._clipboardActivities$.getValue();
    let foundActivityIndex = clipboardActivities.findIndex((el) => el.id === activity.id);
    if (foundActivityIndex === -1) {
      return;
    }
    clipboardActivities[foundActivityIndex] = activity;
    this._clipboardActivities$.next(clipboardActivities);
  }

  clipboardTasks$(): Observable<Activity[]> {
    return this._clipboardActivities$;
  }

  addClipboardActivity(activity: Activity) {
    activity.in_clipboard = true;
    activity.order_in_clipboard = this.orderInClipboard;

    this.progressService.start();
    return this.activitiesService.updateActivityByPk$(activity)
      .pipe(finalize(() => {
        this._clipboardActivities$.next([...this._clipboardActivities$.getValue(), activity]);
        this.notificationService.success({
          description: this.translate.instant(marker('The activity has been successfully added to the clipboard')),
        });
        this.progressService.complete();
      }));
  }

  addClipboardTask(task: PlanningBoardTask, activityStatus: ActivityStatus, notificationMsg: string): Observable<any> {
    task.clipboard = true;
    const activityToClipboard: Activity = this.prepareActivityFromTask(task, activityStatus);
    this.progressService.start();
    return combineLatest([this.tasksService.updateTask$(task), this.generateActivityInClipboard(
      task.id,
      activityToClipboard.description,
      activityToClipboard.duration,
      activityToClipboard.priority.id,
      activityToClipboard.order_in_clipboard,
    )]).pipe(
      tap(([task, activity]) => {
        activityToClipboard.id = activity.id;
      }),
      finalize(() => {
        this._clipboardActivities$.next([...this._clipboardActivities$.getValue(), activityToClipboard]);
        this.notificationService.success({
          description: this.translate.instant(notificationMsg),
        });
        this.progressService.complete();
      }),
      mergeMap(() => of(activityToClipboard)),
    );
  }

  cloneClipboardActivity(activity: Activity, notificationMsg: string): Observable<any> {
    const clonedActivity: Activity = deepClone(activity);
    if (!!activity.done) {
      clonedActivity.done = false;
    }
    this.progressService.start();
    const clipboardActivities = this._clipboardActivities$.getValue();
    const orderInClipboard = clipboardActivities[clipboardActivities.length - 1].order_in_clipboard + sortStep;
    return this.generateActivityInClipboard(
      clonedActivity.task.id,
      clonedActivity.description,
      clonedActivity.duration,
      clonedActivity.priority.id,
      orderInClipboard,
    ).pipe(
      tap((res) => {
        if (!res || !res.id) {
          return;
        }

        clonedActivity.id = res.id;
        this._clipboardActivities$.next([...this._clipboardActivities$.getValue(), clonedActivity]);
        this.newActivityForTask$.next(clonedActivity);
        this.notificationService.success({
          description: this.translate.instant(notificationMsg),
        });
        this.progressService.complete();
      }),
    );
  }

  deleteClipboardActivity(activity: Activity): Observable<any> {
    this.progressService.start();
    return this.activitiesService.deleteActivityFromClipboardAndReturnCorrespondingTaskIfItShouldNotBeClipboard$(activity)
      .pipe(finalize(() => {
        this.removeActivityFromFrontend(activity.id);
        this.progressService.complete();
      }));
  }

  deleteActivity(activity: Activity) {
    if (!!activity.phase) {
      return this.activitiesService.assignTaskToActivity(activity.id, null)
        .pipe(finalize(() => {
          this.removeActivityFromFrontend(activity.id);
        }));
    }

    return this.deleteActivityByPkGQL.mutate({id: activity.id})
      .pipe(finalize(() => {
        this.removeActivityFromFrontend(activity.id);
      }));
  }

  removeActivityFromFrontend(id: BigInt) {
    this._clipboardActivities$.next(this._clipboardActivities$.getValue().filter((el) => +el.id !== +id));
  }

  private generateActivityInClipboard(
    task_id: UUID,
    description: string,
    duration: number,
    priority_id: PriorityID,
    order_in_clipboard: number,
  ): Observable<ChangeTaskStatusActivity> {
    return this.generateDefaultActivityForTaskGQL.mutate({
      task_id,
      description,
      duration,
      priority_id,
      order_in_clipboard,
    }).pipe(map(
      (res) => res['data']['insert_activities_one'],
    ));
  }

  prepareActivityFromTask(task: PlanningBoardTask, activityStatus: ActivityStatus): Activity {
    return {
      id: new Date().getTime(),
      date: null,
      description: task.description,
      duration: task.duration,
      note: null,
      status: activityStatus.key,
      done: false,
      in_clipboard: true,
      order_in_clipboard: this.orderInClipboard,
      order_in_phase: null,
      order_in_task: null,
      task: {
        id: task.id,
        is_implicit: task.is_implicit,
        description: task.description,
        group: task.group,
      },
      priority: task.priority,
      appointment: null,
      templateId: null,
    };
  }

  get orderInClipboard(): number {
    const clipboardActivities = this._clipboardActivities$.getValue();

    return !!clipboardActivities.length
      ? clipboardActivities[clipboardActivities.length - 1].order_in_clipboard + sortStep
      : sortStep;
  }
}
