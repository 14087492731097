import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { UserLocaleService } from '../services/user-locale.service';

@Pipe({name: 'dateTimeFormat'})
export class DateTimeFormatPipe implements PipeTransform {
  constructor(private userLocaleService: UserLocaleService) {
  }
  transform(value: DateTime | undefined, format: string = this.userLocaleService.format.time): string {
    return value ? value.toFormat(format) : '';
  }
}
