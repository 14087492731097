import { RouterModule, Routes } from '@angular/router';
import { DayEditorComponent } from './modules/day-planner/components/day-editor/day-editor.component';
import { AppGuard } from './modules/auth/guards/app.guard';
import { TemplateEditorComponent } from './modules/day-planner/components/template-editor/template-editor.component';
import { RootComponent } from './core/components/root/root.component';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { PrivacyPolicyComponent } from './shared/info-pages/privacy-policy/privacy-policy.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'app',
    canActivate: [AppGuard],
    component: RootComponent,
    children: [
      {
        path: '',
        redirectTo: 'day-editor',
        pathMatch: 'full',
      },
      {
        path: 'day-editor', component: DayEditorComponent,
      },
      {
        path: 'template-editor', component: TemplateEditorComponent,
      },
      {
        path: 'planning-board',
        loadChildren: () => import('./modules/day-planner/components/planning-board/planning-board.module').then((m) => m.PlanningBoardModule),
      },
      {
        path: 'privacy-policy', component: PrivacyPolicyComponent,
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/day-planner/components/settings/settings.module').then((m) => m.SettingsModule),
      },
    ],
  },
];

export const appRoutes = RouterModule.forRoot(routes, {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  initialNavigation: 'enabled',
  scrollOffset: [0, 0],
  useHash: false,
  relativeLinkResolution: 'legacy',
  onSameUrlNavigation: 'reload',
});
