import { Component, Input, OnInit } from '@angular/core';
import { Appointment } from 'src/app/core/models/interfaces';
import { DataproviderService } from 'src/app/core/services/dataprovider.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { UserLocaleService } from 'src/app/shared/services/user-locale.service';

@UntilDestroy()
@Component({
  selector: 'app-appointment-list-entry',
  templateUrl: './appointment-list-entry.component.html',
})
export class AppointmentListEntryComponent implements OnInit {
  @Input() appointment: Appointment;

  constructor(
    private dataProviderService: DataproviderService,
    public userLocaleService: UserLocaleService,
  ) {
  }

  ngOnInit(): void {
    this.dataProviderService.lastUpdatedAppointment$
      .pipe(filter((appointment) => !!appointment && this.appointment.extId === appointment.extId), untilDestroyed(this))
      .subscribe((updatedAppointment) => {
        this.appointment.planned = updatedAppointment.planned;
      });
  }

  toActivityList() {
    this.dataProviderService.appointmentToActivityList(this.appointment);
  }
}
