import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class FetchAllGroupContainersGQL extends Query<any> {
  document = gql`
    query clipboardActivities {
      activities(where: {in_clipboard: {_eq: true}}, order_by: {order_in_clipboard: asc}) {
        id
        note
        date
        status
        phaseDefault
        nextActivityId
        previousActivityId
        duration
        done
        task {
          id
          is_implicit
          group {
           id
          }
        }
        description
        fixedStart
        in_clipboard
        order_in_clipboard
        order_in_phase
        priority {
          id
          description
        }
      }
    }
  `;
}
