<div class="c-templateEditor">
  <div class="c-templateEditor__phases" cdkDropListGroup>
    <app-phase-list class="shadow-box phases"
                    [activityStatuses]="activityStatuses"
                    [priorities]="priorities" [phasesList]="phases"
                    [date]="date"
                    [dayEditor]="false">
      <ng-container data-phasesEmpty>
        <div class="c-phasesList__empty c-phasesList__empty--outline">
          <mat-icon svgIcon="template" class="c-phasesList__emptyIcon"></mat-icon>
          <p class="c-phasesList__emptyTitle">{{'You do not have a selected template' | translate}}</p>
          <p class="c-phasesList__emptyText">{{'Please, create template or select template' | translate}}</p>
          <div class="flex flex-col items-center justify-center">
            <app-button (buttonClicked)="createTemplate()">{{'Create Template' | translate}}</app-button>
            <ng-container *ngIf="templates.length">
              <span class="my-2 text-gray">{{'or' | translate}}</span>
              <mat-form-field appearance="outline">
                <mat-label>{{'Select Template' | translate}}</mat-label>
                <mat-select [(value)]="selectedTemplate" (selectionChange)="selectionChanged($event)">
                  <mat-option *ngFor="let template of templates" [value]="template">
                    {{template.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </app-phase-list>
  </div>
  <div *ngIf="selectedTemplate" class="c-templateEditor__actions">
    <mat-form-field appearance="fill" class="w-full">
      <mat-label>{{'Select Template' | translate}}</mat-label>
      <mat-select [(value)]="selectedTemplate" (selectionChange)="selectionChanged($event)">
        <mat-option *ngFor="let template of templates" [value]="template">
          {{template.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-list class="w-full cursor-pointer">
      <mat-list-item (click)="createTemplate()" matRipple>
        <mat-icon svgIcon="add" mat-list-icon></mat-icon>
        <span mat-line>{{'Create Template' | translate}}</span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item *ngIf="selectedTemplate" (click)="editTemplate()" matRipple>
        <mat-icon svgIcon="edit" mat-list-icon></mat-icon>
        <span mat-line>{{'Edit Template' | translate}}</span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item *ngIf="selectedTemplate" (click)="cloneTemplate()" matRipple>
        <mat-icon svgIcon="content-copy" mat-list-icon></mat-icon>
        <span mat-line>{{'Clone Template' | translate}}</span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item *ngIf="selectedTemplate" (click)="openPhaseAddDialog()" matRipple>
        <mat-icon svgIcon="add" mat-list-icon></mat-icon>
        <span mat-line>{{'Add Phase' | translate}}</span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item *ngIf="selectedTemplate && deleteOn" (click)="deleteTemplate()" matRipple>
        <mat-icon svgIcon="delete" mat-list-icon></mat-icon>
        <span mat-line>{{'Delete Template' | translate}}</span>
      </mat-list-item>
    </mat-list>
  </div>
</div>
