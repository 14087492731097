import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanningBoardClipboardComponent } from './planning-board-clipboard.component';
import { PlanningBoardClipboardService } from './planning-board-clipboard.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../../../../shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    PlanningBoardClipboardComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    SharedModule,
    DragDropModule,
    FormsModule,
  ],
  exports: [
    PlanningBoardClipboardComponent,
  ],
  providers: [
    PlanningBoardClipboardService,
  ],
})
export class PlanningBoardClipboardModule { }
