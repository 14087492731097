import { AppSettingsService } from '../core/services/app-settings.service';
import { AuthService } from '../modules/auth/services/auth.service';
import { take, filter, switchMap, tap } from 'rxjs/operators';
import { UserLocaleService } from '../shared/services/user-locale.service';
import { LanguageService } from '../shared/translates/language.service';

export function AppInitializer(
  authService: AuthService,
  appSettingsService: AppSettingsService,
  userLocaleService: UserLocaleService,
  languageService: LanguageService,
) {
  languageService.initUserLanguage();
  userLocaleService.setLocale(userLocaleService.locale);
  return () => new Promise((resolve) => {
    const appInitializerSub = authService.isLoggedIn$().pipe(
      filter((isLoggedIn) => isLoggedIn),
      take(1),
      switchMap(() => authService.refreshToken()),
      switchMap(() => appSettingsService.initGlobalSettings()),
    ).subscribe(() => {
      appInitializerSub.unsubscribe();
    }).add(resolve);
  });
}

