<div class="c-durationEntry" [ngClass]="{'c-durationEntry--column': column}" [matMenuTriggerFor]="menu">
  <p class="c-durationEntry__title">{{currentDuration}} {{'min' | translate}} <mat-icon class="c-durationEntry__titleIcon">expand_more</mat-icon></p>

  <mat-menu #menu="matMenu" class="c-durationEntry__menu">
    <button class="c-durationEntry__menuItem"
            [class.active]="currentDuration === dur"
            *ngFor="let dur of durations" (click)="selectDuration(dur)" mat-menu-item>
      {{dur}} {{'min' | translate}}
    </button>
  </mat-menu>
</div>
