<div class="c-settingsIntegrations__content">
  <div class="c-settingsIntegrations__header">
    <p class="settingsIntegrations__title">{{'Exchange Online Calendar Feature' | translate}}</p>
    <mat-slide-toggle
      color="primary"
      [(ngModel)]="exchangeFeatureSelected"
      (ngModelChange)="selectExchangeFeature($event)"></mat-slide-toggle>
  </div>
  <div class="c-settingsIntegrations__body">
    <ng-container *ngIf="calendarListSub.closed else settingsLoading">
      <ul class="c-settingsIntegrations__list">
        <li class="c-settingsIntegrations__listItem" *ngFor="let cal of calendars; let i=index">
          <div>
            <div class="calendar-entry">{{cal.summary}}</div>
          </div>
          <mat-checkbox color="primary" [(ngModel)]="cal.enabled" (ngModelChange)="selectCalendar(cal)"></mat-checkbox>
        </li>
      </ul>
    </ng-container>
    <ng-template #settingsLoading>
      <spinner class="c-settings__spinner"></spinner>
    </ng-template>
  </div>
</div>
