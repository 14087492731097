import { Component, EventEmitter, OnInit } from '@angular/core';
import { ExchangeOnlineCalendarService } from '../../services/exchange-online-calendar.service';
import { AppSettingsService } from '../../../../core/services/app-settings.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GlobalSettingsKeys } from '../../../../core/models/enums';
import { MsalService } from '@azure/msal-angular';
import { take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Observable, Subscription } from 'rxjs';
import { Calendar } from '../../../google/services/google-calendar.service';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@UntilDestroy()
@Component({
  selector: 'exchange-online',
  templateUrl: './exchange-online.component.html',
  styleUrls: ['./exchange-online.component.scss'],
})
export class ExchangeOnlineComponent implements OnInit {
  exchangeFeatureSelected = false;
  loginDisplay = false;
  calendars: Calendar[] = [];
  calendarListSub = Subscription.EMPTY;

  constructor(
    private msalService: MsalService,
    private translate: TranslateService,
    private appSettingsService: AppSettingsService,
    private notificationService: NotificationService,
    private exchangeOnlineCalendarService: ExchangeOnlineCalendarService,
  ) {
  }

  ngOnInit(): void {
    this.setLoginDisplay();

    this.appSettingsService.isExchangeOnlineCalEnabled$()
      .pipe(untilDestroyed(this))
      .subscribe((enabled) => {
        this.exchangeFeatureSelected = enabled;
        if (enabled) {
          this.getListCalendars();
        } else {
          this.calendars = [];
        }
      });
  }


  selectExchangeFeature(enabled: EventEmitter<any>) {
    const authMsalObservable: Observable<any> = enabled ? this.msalService.loginPopup() : this.msalService.logoutPopup();
    authMsalObservable
      .pipe(take(1), untilDestroyed(this))
      .subscribe((result) => {
          if (result) {
            this.exchangeOnlineCalendarService.setExchangeOnlineJwtData(result);
            this.setLoginDisplay();
            this.getListCalendars();
          }
          this.appSettingsService.setGlobalSettingsValue(GlobalSettingsKeys.EXCHANGE_ONLINE_CALENDAR_ENABLED, enabled.toString());
        }, (error) => {
          this.notificationService.error({
            title: this.translate.instant(marker('Exchange error')),
            description: error,
          });
        },
      );
  }

  getListCalendars() {
    this.calendarListSub = this.exchangeOnlineCalendarService.getListCalendars()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((calendars) => {
        this.calendars = calendars;
      });
  }

  selectCalendar(calendar: Calendar) {
    this.exchangeOnlineCalendarService.selectCalendar(calendar)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => calendar.configured = true);
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }
}
