import { CommonModule } from '@angular/common';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { translateLoaderFactory, translateParserFactory } from './translate-helper';
import { MissingTranslationService } from './missing-translation-service.service';
import { LanguageService } from './language.service';
import { DynamicLocaleId } from './dynamic-locale.id';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
      },
      parser: {
        provide: TranslateParser,
        useFactory: translateParserFactory,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationService,
      },
      useDefaultLang: true,
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useClass: DynamicLocaleId,
    },
  ],
})

export class RootTranslateModule {
  constructor() {
  }
  static forRoot(): ModuleWithProviders<RootTranslateModule> {
    return {
      ngModule: RootTranslateModule,
    };
  }
}
