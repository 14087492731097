import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class MoveActivityInsideTaskGQL extends Mutation {
  document = gql`
    mutation MoveActivityInsideTaskGQL($id: bigint!, $order_in_task: Int) {
      update_activities_by_pk(pk_columns: {id: $id}, _set: {order_in_task: $order_in_task}) {
        id
      }
    }
  `;
}
