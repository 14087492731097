import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';


@Injectable({providedIn: 'root'})
export class GoogleSigninService {
  private auth2: gapi.auth2.GoogleAuth;
  private subject = new ReplaySubject<gapi.auth2.GoogleUser>(1);

  public gapiSetup: boolean = false; // marks if the gapi library has been loaded
  public authInstance: gapi.auth2.GoogleAuth;
  public error: string;
  public user: gapi.auth2.GoogleUser | undefined;

  constructor(private _authService: AuthService) {}

  async initGoogleAuth(): Promise<void> {
    //  Create a new Promise where the resolve
    // function is the callback passed to gapi.load
    const pload = new Promise((resolve) => {
      gapi.load('auth2', resolve);
    });

    // When the first promise resolves, it means we have gapi
    // loaded and that we can call gapi.init
    return pload.then(async () => {
      await gapi.auth2.init({
          client_id: '272805985940-ja1rpo91eos3daqf02soou6qs40e7tj3.apps.googleusercontent.com',
        }).then((auth) => {
          this.gapiSetup = true;
          this.authInstance = auth;
        });
    });
  }

  async signIn(): Promise<void> {
    // Initialize gapi if not done yet
    if (!this.gapiSetup || !this.authInstance) {
      await this.initGoogleAuth();
    }
    if (!!this.user) {
      this.subject.next(this.user);
      return new Promise((resolve) => resolve());
    }

    await this.clientLoad();

    // Resolve or reject signin Promise
    return new Promise<void>( async (resolve) => {
      if (!this.authInstance.isSignedIn.get()) {
        await this._authService.logout$.subscribe(() => this.signout());
        await this.authInstance.signIn({
          scope: 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/contacts.readonly',
          prompt: 'select_account',
        }).then((user) => {
            this.user = user;
            this.subject.next(this.user);
          }, (error) => {
            this.error = error;
            this.user = undefined;
            this.subject.next(undefined);
          },
        );
      } else {
        this.user = this.authInstance.currentUser.get();
        this.subject.next(this.user);
      }
      resolve();
    });
  }

  async checkIfUserAuthenticated(): Promise<boolean> {
    // Initialize gapi if not done yet
    if (!this.gapiSetup) {
      await this.initGoogleAuth();
    }
    return this.authInstance.isSignedIn.get();
  }

  public signout() {
    if (!!this.authInstance) {
      this.authInstance.signOut().then(() => {
        this.authInstance.disconnect();
        this.gapiSetup = false;
        this.user = undefined;
        this.subject.next(this.user);
      });
    }
  }

  public observable(): Observable<gapi.auth2.GoogleUser> {
    return this.subject.asObservable();
  }

  private clientInit() {
    return gapi.client.init({
      apiKey: 'AIzaSyBp0musNcvk5Ucq3NpEHcoCr_qaJFGxxIk',
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest', 'https://www.googleapis.com/discovery/v1/apis/people/v1/rest'],
    });
  }

  public clientLoad(): Promise<void> {
    return new Promise((resolve) => {
      if (!!gapi.client) {
        resolve();
      } else {
        gapi.load('client', () => {
          this.clientInit().then(() => resolve());
        });
      }
    });
  }
}
