import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class MoveActivityByIdGQL extends Mutation {
  document = gql`
    mutation moveActivityById(
      $id: BigInt!
      $toId: BigInt!
      $dir: MoveActivityDir!
    ) {
      moveActivityById(id: $id, toId: $toId, dir: $dir) {
        activity {
          id
          note
          date
          phaseDefault
          nextActivityId
          previousActivityId
          duration
          done
          description
          fixedStart
          templateId
          priority {
            id
            description
          }
          phase {
            id
          }
          appointment {
            endTime
            extId
            extLink
            id
            provider
            startTime
            summary
          }
        }
        changedActivities {
          i
          p
          n
          ph
          date
        }
        errors
      }
    }
  `;
}
