import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class FetchGroupContainerStatusesGQL extends Query<any> {
  document = gql`
  query FETCH_GROUPS_STATUSES_GQL {
    statuses(where: {entity: {_eq: "group"}}) {
      is_new
      is_resolved
      entity
      key
    }
  }
  `;
}
