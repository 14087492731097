import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie';

@Injectable({providedIn: 'root'})
export class ThemeService {
  prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
  darkModeEnabled$ = new BehaviorSubject<boolean>(false);

  constructor(private cookie: CookieService) {}

  initTheme(shouldAdd: string) {
    this.prefersDark.addEventListener('change', (mediaQuery) => {
      this.darkModeEnabled$.next(mediaQuery.matches);
      this.toggleDarkTheme(mediaQuery.matches);
    });
    const theme = shouldAdd ? shouldAdd === 'true' : this.prefersDark.matches || false;
    this.toggleDarkTheme(theme);
  }

  toggleDarkTheme(shouldAdd) {
    this.cookie.put('dark', shouldAdd);
    this.darkModeEnabled$.next(shouldAdd);
    document.body.classList.toggle('dark', shouldAdd);
  }
}
