<form class="c-dialog" [formGroup]="form" (ngSubmit)="onSubmit()">
  <button class="c-dialog__close" matDialogClose><mat-icon svgIcon="close"></mat-icon></button>

  <div class="c-dialog__body">
    <p class="c-dialog__title">{{'Add a new Phase' | translate}}</p>

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{'Phase Name' | translate}}</mat-label>
      <input matInput type="text" [placeholder]="'Enter Phase name...' | translate" autocomplete="off" formControlName="name" required>
      <mat-error *ngIf="getFormControlsError('name', 'required')">
        {{'Please, enter phase name' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{'Start time' | translate}}</mat-label>
      <input matInput type="time" autocomplete="off" formControlName="fixedStart" readonly required>
      <mat-icon matSuffix svgIcon="schedule"
                style="cursor: pointer;"
                [ngxTimepicker]="picker"
                [format]="+timeFormat"></mat-icon>
      <ngx-material-timepicker disableAnimation="true"
                               [minutesGap]="5"
                               [cancelBtnTmpl]="cancelBtnTmpl"
                               [confirmBtnTmpl]="confirmBtnTmpl"
                               [theme]="timepickerTheme"
                               (timeSet)="form.get('fixedStart').patchValue($event)" #picker></ngx-material-timepicker>
      <ng-template #cancelBtnTmpl>
        <app-button btnStyle="outline">{{'Cancel' | translate}}</app-button>
      </ng-template>
      <ng-template #confirmBtnTmpl>
        <app-button class="ml-3 block" style="min-width: 112px;">{{'Set' | translate}}</app-button>
      </ng-template>

      <mat-error *ngIf="getFormControlsError('fixedStart', 'required')">
        {{'Please, enter fixed start time' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="c-dialog__footer">
    <div class="c-dialog__footerBtns">
      <app-button btnStyle="outline" type="button" matDialogClose>{{'Cancel' | translate}}</app-button>
      <app-button type="submit">{{'Add phase' | translate}}</app-button>
    </div>
  </div>
</form>
