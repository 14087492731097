import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { DateTime } from 'luxon';
import { SEC_IN_MINUTE } from './constants.helper';

export const cdkDragMove = new Subject<boolean>();
export const TIME_DURATIONS: number[] = [0, 5, 10, 15, 20, 25, 30, 45, 60, 75, 90, 105, 120, 150, 180, 210, 240, 300, 360, 420];

export enum DropDirectionState {
  Before = 'BEFORE',
  After = 'AFTER',
}

export function getDirectionState(event: CdkDragDrop<any[]>) {
  return event.currentIndex < event.previousIndex ? DropDirectionState.Before : DropDirectionState.After;
}

export function convertUTCDateToLocalDate(date) {
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

export const ngxMaterialTimepickerTheme = {
  dial: {
    dialBackgroundColor: '#3377ea',
  },
  clockFace: {
    clockHandColor: '#3377ea',
  },
  container: {
    buttonColor: '#3377ea',
  },
};

export function debounce(delay: number = 300): MethodDecorator {
  return function (target, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const timeoutKey = Symbol();

    const original = descriptor.value;

    descriptor.value = function (...args) {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}

export function convertHoursToMinutes(time: DateTime, lastHour = false) {
  let minutes = time.minute;
  if (lastHour) {
    minutes += 1;
  }
  return time.hour * SEC_IN_MINUTE + minutes;
}

export function convertMinutesToHours(duration: number) {
  const hours = duration / 60 | 0;
  const minutes = duration % 60;
  return hours + ':' + (minutes > 0 ? minutes : '00');
}

export function lightOrDark(color) {

  // Variables for red, green, blue values
  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  if (hsp > 127.5) {
    return '#000';
  } else {
    return '#fff';
  }
}

export function deepClone(obj) {
  if (obj === null) return null;
  const clone = Object.assign({}, obj);
  Object.keys(clone).forEach((key) =>
    clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key],
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
}

export function randomNumber(min: number, max: number) {
  return (Math.random() * (max - min) + min).toFixed();
}
