import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TIME_DURATIONS } from '../../../../_helpers/helpers';

@Component({
  selector: 'duration-entry',
  templateUrl: './duration-entry.component.html',
  styleUrls: ['./duration-entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DurationEntryComponent implements OnInit {
  @Input() currentDuration: number = null;
  @Input() column: boolean = false;
  durations = TIME_DURATIONS;

  @Output() durationChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


  selectDuration(value: number) {
    this.currentDuration = value;
    this.durationChanged.emit(value);
  }
}
