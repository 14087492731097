import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { PASSWORD_MIN_LENGTH } from '../../../../_helpers/constants.helper';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'input-password',
  templateUrl: './input-password.component.html',
})
export class InputPasswordComponent {
  @Input() control: AbstractControl = new FormControl('');
  @Input() autoCompleteType: 'on' | 'off' = 'on';
  @Input() placeholder: string = '';
  @Input() idName: string = '';
  @Input() label: string = '';
  @Input() classes: string = '';
  @Input() requiredError: string = marker('Please, enter password!');
  @Input() appearance: MatFormFieldAppearance = 'outline';

  hideNewPass = true;
  minPassLength = PASSWORD_MIN_LENGTH;
}
