import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'onboarding-dialog',
  templateUrl: './onboarding-dialog.component.html',
  styleUrls: ['./onboarding-dialog.component.scss'],
})
export class OnboardingDialogComponent implements OnInit {

  constructor(
    private cookie: CookieService,
    private dialogRef: MatDialogRef<OnboardingDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
    this.cookie.put('first-onboarding-dialog', 'true');
  }
}
