import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Activity, Phase } from '../core/models/interfaces';

@Injectable({providedIn: 'root'})
export class PhaseSorter {
  public sort(activities: Map<number, Activity>, phases: Phase[]) {
    phases.sort(PhaseSorter.compareFixedStartPhase);
    phases.forEach((phase) => this.sortPhase(phase, activities));
    this.checkForTimeFailures(phases, activities);
  }

  private sortPhase(phase: Phase, activities: Map<number, Activity>) {
    phase.activities = [];
    from(activities.values()).pipe(
      filter((activity) => phase.id === activity.phase?.id),
      tap((activity) => phase.activities.push(activity)),
    ).subscribe();
    phase.activities = PhaseSorter.sortActivities(phase);
  }

  /**
   * sorts the activities with in a phase based on the
   * previous and next activity ids - doubly linked list
   * and calculate start and end times
   * @param phase
   * @return array of activities
   */
  private static sortActivities(phase: Phase): Activity[] {
    const unsorted = phase.activities;
    const sorted: Activity[] = [];

    // finding the first activity in unsorted
    // based on the condition that there is no activity
    // in the list, which has the previousActivityId of the first element
    // and push it as first element to sorted
    PhaseSorter.pushAsFirstElementToSorted(sorted, unsorted);

    // now pushing the activities in order to
    // the sorted list
    PhaseSorter.pushingActivitiesInSortedList(sorted, unsorted);

    // now calculate the start and end times
    // based on the fixedStart time of the phase
    let fixedStart = phase.fixedStart;

    let durSum = 0;
    for (const act of sorted) {
      if (act.fixedStart) {
        fixedStart = act.fixedStart;
        durSum = 0;
      }
      act.actStart = fixedStart.plus({minutes: durSum});
      durSum = durSum + +act.duration;
      act.actEnd = fixedStart.plus({minutes: durSum});
    }
    return sorted;
  }

  private static pushAsFirstElementToSorted(sorted: Activity[], unsorted: Activity[]) {
    for (const act of unsorted) {
      let first = true;
      for (const actSearch of unsorted) {
        if (act.previousActivityId === actSearch.id) {
          first = false;
        }
      }
      if (first) {
        sorted.push(act);
      }
    }
  }

  private static pushingActivitiesInSortedList(sorted: Activity[], unsorted: Activity[]) {
    while (sorted.length < unsorted.length) {
      for (const act of sorted) {
        for (const actCheck of unsorted) {
          if (actCheck.id === act.nextActivityId) {
            sorted.push(actCheck);
          }
        }
      }
    }
  }

  /**
   * checks for time failures
   * 1) when endTime of an activity is greater
   *    than the fixedStartTime of the next activity or phase
   * 2) when startTime of a fixedStart activity is less
   *    than the startTime of the phase
   * @param phases
   * @param activities
   */
  private checkForTimeFailures(phases: Phase[], activities: Map<number, Activity>) {
    let lastAct: Activity | undefined;
    from(phases).pipe(
      map((phase) => {
        phase.activities.forEach((activity) => activity.timeFailure = false);
        return phase;
      }),
      mergeMap((phase) => from(phase.activities)),
      tap((activity) => {
        if (lastAct !== undefined && lastAct.actEnd && activity.actStart) {
          // 1) when endTime of an activity is greater than the fixedStartTime of the next activity or phase
          if (lastAct.actEnd > activity.actStart && !lastAct.timeFailure) {
            activities.get(lastAct.id)!.timeFailure = true;
          }
          // 2) when startTime of a fixedStart activity is less than the startTime of the phase
          if (lastAct.actEnd > activity.actStart && lastAct.phaseDefault) {
            activities.get(activity.id)!.timeFailure = true;
          }
        }
        lastAct = activity;
      }),
    ).subscribe();
  }

  private static compareFixedStartPhase(a: Phase, b: Phase) {
    if (a.fixedStart < b.fixedStart) {
      return -1;
    }
    if (a.fixedStart > b.fixedStart) {
      return 1;
    }
    return 0;
  }
}
