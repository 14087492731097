<div class="c-settingsIntegrations__content">
  <div class="c-settingsIntegrations__header">
    <p class="settingsIntegrations__title">{{'EWS Calendar Feature' | translate}}</p>
    <mat-slide-toggle
      color="primary"
      [(ngModel)]="ewsFeatureSelected"
      (ngModelChange)="selectEwsFeature($event)"></mat-slide-toggle>
  </div>
  <div *ngIf="ewsFeatureSelected" class="c-settingsIntegrations__body">
    <form [formGroup]="ewsConfigForm" class="w-full flex flex-col mt-2.5" (ngSubmit)="onEwsConfigSubmit()">
      <div class="flex items-center">
        <mat-form-field appearance="outline" class="flex-1 mr-2.5">
          <mat-label>{{'URL' | translate}}</mat-label>
          <input matInput type="text" [placeholder]="'Enter EWS url...' | translate" autocomplete="off" formControlName="ews_url"
                 required>
          <mat-error>{{'Please, enter url' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-1">
          <mat-label>{{'User' | translate}}</mat-label>
          <input matInput type="text" [placeholder]="'Enter EWS user...' | translate" autocomplete="off" formControlName="ews_user"
                 required>
          <mat-error>{{'Please, enter user' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="flex items-center">
        <mat-form-field appearance="outline" class="flex-1 mr-2.5">
          <mat-label>{{'Password' | translate}}</mat-label>
          <input matInput type="password" [placeholder]="'Enter EWS password...' | translate" autocomplete="off"
                 formControlName="ews_password" required>
          <mat-error>{{'Please, enter password' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-1">
          <mat-label>{{'Select Exchange Version' | translate}}</mat-label>
          <mat-select formControlName="ews_exchange_version">
            <mat-option *ngFor="let ewsVersion of ewsVersions" [value]="ewsVersion">
              {{ewsVersion}}
            </mat-option>
          </mat-select>
          <mat-error>{{'Please, select Exchange Version' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="flex justify-end">
        <div class="flex items-center mt-5 w-1/2">
          <app-button btnStyle="outline" type="button" (buttonClicked)="checkEwsConfig()" class="mr-2.5 flex-1">{{'Check Config' | translate}}</app-button>
          <app-button type="submit" class="flex-1">{{'Save' | translate}}</app-button>
        </div>
      </div>
    </form>
  </div>
</div>
