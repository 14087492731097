import { PoBrowserLoader } from './po-loaders/po-browser-loader';
import { InterpolatedTranslateParser } from './interpolated-translate-parser';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export function translateLoaderFactory() {
  return new PoBrowserLoader();
}

export interface LanguageConfig {
  name: string;
  id: string;
}

export enum LanguagesCodesEnum {
  Russia = 'ru',
  English = 'en',
  German = 'de',
  Ukrainian = 'uk',
}

export const DEFAULT_LANG = LanguagesCodesEnum.English;

export type Lang = keyof typeof LANGUAGES;

export const LANGUAGES: {[l in string]: LanguageConfig} = {
  en: {id: LanguagesCodesEnum.English, name: 'English'},
  de: {id: LanguagesCodesEnum.German, name: 'German'},
  uk: {id: LanguagesCodesEnum.Ukrainian, name: 'Ukrainian'},
  ru: {id: LanguagesCodesEnum.Russia, name: 'Russia'},
};

export function translateParserFactory() {
  return new InterpolatedTranslateParser();
}

export function getSupportedLangs(): Lang[] {
  return Object.keys(LANGUAGES);
}

export function convertTranslateDictionary(s: object) {
  return Object.keys(s).reduce((prev, next) => Object.assign(prev, s[next]), {});
}

const KeysFromBackend = [
  marker('Urgent'),
  marker('High'),
  marker('Normal'),
  marker('Low'),
];
