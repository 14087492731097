import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class RemoveActivityFromListGQL extends Mutation {
  document = gql`
    mutation removeActivityFromList($id: BigInt!) {
      removeActivityFromList(id: $id) {
        activity {
          id
        }
        changedActivities {
          date
          i
          n
          p
          ph
        }
        errors
      }
    }
  `;
}
