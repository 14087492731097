import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class CreatePhaseGQL extends Mutation {
  // TODO: adding template id
  document = gql`
    mutation createPhase($date: ISO8601Date!, $name: String!, $fixedStart: String!, $duration: Int, $templateId: BigInt!){
      createPhase (date: $date, name: $name, fixedStart: $fixedStart, duration: $duration, templateId: $templateId)
      {
        phase {
            id
            date
            name
            fixedStart
            templateId
        }
        activity {
          id
          description
          date
          duration
          phaseDefault
          phase {
            id
          }
          priority {
            id
            description
          }
          previousActivityId
          nextActivityId
        }
        changedActivities {
          i
          p
          n
          ph
        }
        errors
      }
    }
  `;
}
