import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class NewActivityToPhaseGQL extends Mutation {
  document = gql`
    mutation newActivityToPhase($activity: ActivityCreateInput!) {
      newActivityToPhase(activity: $activity) {
        activity {
          id
          note
          description
          date
          fixedStart
          duration
          templateId
          done
          phase {
            id
            name
          }
          priority {
            id
            description
          }
          previousActivityId
          nextActivityId
        }
        changedActivities {
          i
          p
          n
          ph
        }
        errors
      }
    }
  `;
}
