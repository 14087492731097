import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class FinishGroupGQL extends Mutation {
  document = gql`
  mutation FINISH_GROUP_GQL(
    $id: BigInt!,
    $unfinishedTasks: String
  ) {
    finishGroup(id: $id, unfinishedTasks: $unfinishedTasks) {
      errors
      changedTasks {
        id
        description
      }
      group {
        id
        title
      }
    }
  }`;
}
