import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class UpdateGroupContainerByPkGQL extends Mutation {
  document = gql`
  mutation MyMutation(
    $background: String,
    $position: String,
    $title: String,
    $width: Int,
    $id: bigint!
  ) {
    update_groups_by_pk(
      pk_columns: {
        id: $id
      },
      _set: {
        background: $background,
        title: $title,
        position: $position,
        width: $width
      }
    ) {
      updated_at
    }
  }`;
}
