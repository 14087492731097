import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { CoreModule } from './core/core.module';
import { DayPlannerModule } from './modules/day-planner/day-planner.module';
import { SharedModule } from './shared/shared.module';
import { AppInitializer } from './_helpers/appInitializer';
import { AuthService } from './modules/auth/services/auth.service';
import { PlaygroundModule } from './modules/playground/playground.module';
import { CommonModule } from '@angular/common';
import { AppSettingsService } from './core/services/app-settings.service';
import { GoogleFeaturesModule } from './modules/google/google-features.module';
import { appRoutes } from './app-routing.module';
import { CookieModule } from 'ngx-cookie';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { JwtInterceptor } from './modules/auth/_helpers/jwt.interceptor';
import { NgProgressModule } from 'ngx-progressbar';
import { MicrosoftModule } from './modules/microsoft/microsoft.module';
import { ErrorsInterceptor } from './core/interceptors/errors.interceptor';
import { UserLocaleService } from './shared/services/user-locale.service';
import { RootTranslateModule } from './shared/translates/root-translate.module';
import { LanguageService } from './shared/translates/language.service';
import { HotToastModule } from '@ngneat/hot-toast';
import { TOAST_GLOBAL_CONFIG } from './shared/components/toasts/toasts.helper';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    appRoutes,
    HttpClientModule,
    BrowserAnimationsModule,
    CookieModule.forRoot({
      expires: new Date().getFullYear() + 1 + '-12-31T22:00:00.000Z',
    }),
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    CoreModule,
    SharedModule,
    DayPlannerModule,
    PlaygroundModule,
    MicrosoftModule,
    GoogleFeaturesModule,
    MatSlideToggleModule,
    NgProgressModule,
    RootTranslateModule.forRoot(),
    HotToastModule.forRoot(TOAST_GLOBAL_CONFIG),
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: AppInitializer, multi: true, deps: [AuthService, AppSettingsService, UserLocaleService, LanguageService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
    UserLocaleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
