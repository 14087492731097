import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class NumberOfOpenedActivitiesForTaskGQL extends Query<any> {
  document = gql`
  query NUMBER_OF_OPENED_ACTIVITIES_FOR_TASK_BY_PK_GQL($id: uuid) {
    activities_aggregate(where: {_and: {status: {_neq: "DONE"}, task: {id: {_eq: $task_id}}}}) {
      aggregate {
        count
      }
      nodes {
        id
      }
    }
  }`;
}
