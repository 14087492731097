import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class GetPrioritiesGQL extends Query<any> {
  document = gql`
    query priorities {
      priorities {
        standardPrio
        description
        sortOrder
        id
      }
    }
  `;
}
