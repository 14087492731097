import { Component, EventEmitter, Input, Output } from '@angular/core';

const BTN_COLORS = {
  transparent: 'btn-transparent',
};

const BTN_STYLES = {
  outline: 'btn-outline',
};

@Component({
  selector: 'app-button',
  templateUrl: './app-button.component.html',
})
export class AppButtonComponent {
  readonly BTN_COLORS = BTN_COLORS;
  readonly BTN_STYLES = BTN_STYLES;
  @Input() btnColor: keyof typeof BTN_COLORS;
  @Input() btnStyle: keyof typeof BTN_STYLES;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() type: 'button' | 'submit' = 'button';
  @Output() buttonClicked = new EventEmitter();

  constructor() {}

  btnClickEmit() {
    if (!this.loading) {
      this.buttonClicked.emit();
    }
  }
}
