import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Template } from 'src/app/core/models/interfaces';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'select-template-dialog',
  templateUrl: './select-template-dialog.html',
})
export class SelectTemplateDialog implements OnInit {
  selectedTemplateId: FormControl = new FormControl('', [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {templates: Template[]},
    public dialogRef: MatDialogRef<SelectTemplateDialog>,
  ) { }

  ngOnInit(): void {}

  onOk() {
    if (this.selectedTemplateId.valid) {
      this.dialogRef.close({id: this.selectedTemplateId.value});
    }
  }
}
