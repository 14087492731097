<mat-sidenav-container>
  <mat-sidenav mode="side" opened class="mat-elevation-z8">
    <button mat-button class="menu-button" routerLink="change-email">
      <span>{{'Change Email' | translate}}</span>
    </button>
    <button mat-button class="menu-button" routerLink="change-password">
      <span>{{'Change Password' | translate}}</span>
    </button>
    <button mat-button class="menu-button" routerLink="google-calendar-settings">
      <span>{{'Google Calendar' | translate}}</span>
    </button>
  </mat-sidenav>
  <mat-sidenav-content>
      <div class="content mat-elevation-z8">
        <router-outlet></router-outlet>
      </div>
  </mat-sidenav-content>
</mat-sidenav-container>


