import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class GetEwsExchangeVersionsGQL extends Query<any> {
  document = gql`
    query ews_exchange_versions {
      ews_exchange_versions(order_by: {version: asc}) {
        version
      }
    }
  `;
}
