import { Injectable } from '@angular/core';
import { GoogleSigninService } from './google-signin.service';
import { HttpClient } from '@angular/common/http';
import { AppSettingsService } from '../../../core/services/app-settings.service';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

export interface GoogleContactsInterface {
  emails: {
    metadata: {
      primary: boolean;
      source: {
        id: string;
        type: string;
      };
    };
    value: string;
  }[];
  phones: {
    metadata: {
      primary: boolean;
      source: {
        id: string;
        type: string;
      };
    };
    canonicalForm: string;
    value: string;
  }[];
  displayName: string,
  displayNameLastFirst: string;
  familyName: string;
  givenName: string;
}

export const maxContactsNumber = 10;
const SAVED_CONTACTS_STORAGE_KEY = 'saved-contacts';

@Injectable({providedIn: 'root'})
export class GoogleContactsService {
  private _savedContacts: GoogleContactsInterface[] = JSON.parse(localStorage.getItem(SAVED_CONTACTS_STORAGE_KEY) || '[]');

  constructor(
    private googleSigninService: GoogleSigninService,
    private http: HttpClient,
    private appSettingsService: AppSettingsService,
  ) {
  }

  get savedContacts(): GoogleContactsInterface[] {
    return this._savedContacts;
  }

  saveNewContact(contact: GoogleContactsInterface) {
    const contactExist = this._savedContacts.find((el) => el.displayName === contact.displayName);

    if (!!contactExist) {
      this._savedContacts = this._savedContacts.filter((el) => el.displayName !== contactExist.displayName);
    }

    if (this._savedContacts.length > maxContactsNumber - 1) {
      this._savedContacts.splice(-1, 1);
    }

    this.syncContacts([contact, ...this._savedContacts]);
  }

  private syncContacts(contacts: GoogleContactsInterface[]) {
    this._savedContacts = contacts;
    localStorage.setItem(SAVED_CONTACTS_STORAGE_KEY, JSON.stringify(this._savedContacts));
  }

  private initGoogleIntegration(): Observable<boolean> {
    return this.googleContactsEnabled()
      .pipe(
        filter((isGoogleContactsEnabled) => !!isGoogleContactsEnabled),
        mergeMap(async () => {
          let isUserAuthenticated = false;
          await this.googleSigninService.signIn();
          await this.googleSigninService.checkIfUserAuthenticated().then((userAuthenticated) => {
            isUserAuthenticated = userAuthenticated;
          });
          return isUserAuthenticated;
        }),
        filter((isUserAuthenticated) => isUserAuthenticated),
      );
  }

  public getAllPeople(): Observable<GoogleContactsInterface[]> {
    return this.initGoogleIntegration().pipe(switchMap(() => this.getContactsRequest()));
  }

  public searchPeople(value: string): Observable<GoogleContactsInterface[]> {
    return this.initGoogleIntegration().pipe(switchMap(() => this.searchPeopleRequest(value)));
  }

  public googleContactsEnabled(): Observable<boolean> {
    return combineLatest([
      this.appSettingsService.isGoogleEnabled$(),
      this.appSettingsService.isGoogleContactsEnabled$(),
    ]).pipe(
      filter(([isGoogleEnabled, isGoogleContactsEnabled]) => !!isGoogleEnabled && !!isGoogleContactsEnabled),
      map(([isGoogleEnabled, isGoogleContactsEnabled]) => {
        return !!isGoogleEnabled && !!isGoogleContactsEnabled;
      }),
    );
  }

  private async searchPeopleRequest(value: string): Promise<GoogleContactsInterface[]> {
    let result = null;
    await gapi.client.people.people.searchContacts({
      query: value,
      readMask: 'names,phoneNumbers,addresses,emailAddresses',
    }).then((res) => {
      result = res.result.results;
    });

    result = !!result && result.length
      ? result.map((el) => GoogleContactsService.prepareGooglePerson(el.person))
      : [];

    return result;
  }

  private async getContactsRequest(): Promise<GoogleContactsInterface[]> {
    let result = null;
    await gapi.client.people.people.connections.list({
      'resourceName': 'people/me',
      'pageSize': maxContactsNumber,
      'personFields': 'names,emailAddresses,phoneNumbers',
    }).then((res) => {
      result = res.result;
    });

    result = !!result && result.connections
      ? result.connections.map((el) => GoogleContactsService.prepareGooglePerson(el))
      : [];

    return result;
  }

  private static prepareGooglePerson(person) {
    const names = person.names && person.names.length ? person.names : [];
    const phones = person.phoneNumbers && person.phoneNumbers.length ? person.phoneNumbers : [];
    return {
      emails: person.emailAddresses || [],
      phones: phones,
      displayName: names[0]?.displayName || null,
      displayNameLastFirst: names[0]?.displayNameLastFirst || null,
      familyName: names[0]?.familyName || null,
      givenName: names[0]?.givenName || null,
    } as GoogleContactsInterface;
  }
}
