<div class="relative flex flex-col w-full h-full">
  <div class="flex flex-row items-center">
    <div class="flex flex-col">
      <p *ngIf="!!toastRef.data.title" class="mb-1 text-base font-bold text-gray-900">
        {{ toastRef.data.title | translate }}
      </p>
      <p *ngIf="!!toastRef.data.description" class="text-sm font-normal text-gray-900">
        {{ toastRef.data.description | translate }}
      </p>
    </div>
  </div>
  <button *ngIf="!!toastRef.data.btnCaption"
          class="px-2.5 py-2 mt-2 ml-auto text-black bg-white bg-opacity-30 ng-star-inserted whitespace-nowrap text-sm rounded-md hover:bg-opacity-40"
          (click)="toastRef.data.func()" matRipple>
    {{toastRef.data.btnCaption | translate}}
  </button>
</div>
