import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Activity, Priority } from '../../../../../core/models/interfaces';
import { DataproviderService } from '../../../../../core/services/dataprovider.service';
import { PlanningBoardClipboardService } from '../planning-board-clipboard/planning-board-clipboard.service';
import { TasksService } from '../tasks.service';
import { filter, finalize, switchMap, take } from 'rxjs/operators';
import { NotificationService } from '../../../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivitiesService } from '../activities.service';
import { AssignedGroupContainer, ActivityStatus, PlanningBoardActivityParent } from '../planning-board.helper';
import { NgProgressService } from '../../../../../shared/services/ng-progress.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { ConfirmationService } from '../../../../../core/services/confirmation.service';

@UntilDestroy()
@Component({
  selector: 'planning-board-activity',
  templateUrl: './planning-board-activity.component.html',
  styleUrls: ['./planning-board-activity.component.scss'],
})
export class PlanningBoardActivityComponent {
  @Input() parent: PlanningBoardActivityParent = 'clipboard';
  @Input() activity: Activity;
  @Input() activityStatuses: ActivityStatus[] = [];
  @Input() priorities: Priority[] = [];
  @Input() groupsWithTasks: AssignedGroupContainer[] = [];
  @Output() cloneActivity = new EventEmitter();
  @Output() activityRemoved = new EventEmitter();

  cdkDragPreviewWidth: number;

  constructor(
    private dataProviderService: DataproviderService,
    private tasksService: TasksService,
    private activitiesService: ActivitiesService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private progressService: NgProgressService,
    private router: Router,
    private cookie: CookieService,
    private planningBoardClipboardService: PlanningBoardClipboardService,
    private confirmationService: ConfirmationService,
  ) {
  }

  get cannotToClipboardTooltip(): string {
    if (this.activity.in_clipboard) {
      return marker('This activity is on the clipboard');
    }

    if (!!this.activity.phase) {
      return marker('This activity is already scheduled in my day');
    }

    if (this.activity.done) {
      return marker('This activity has already been completed');
    }

    return marker('Move to clipboard');
  }

  cdkDragStarted(event: any) {
    this.cdkDragPreviewWidth = event.source.element.nativeElement.offsetWidth / 2;
  }

  removeFromClipboard() {
    this.activity.in_clipboard = false;
    this.planningBoardClipboardService.deleteClipboardActivity(this.activity)
      .pipe(take(1), untilDestroyed(this)).subscribe();
  }

  moveToClipboard() {
    if (!!this.activity.phase || this.activity.in_clipboard) {
      return;
    }
    this.planningBoardClipboardService.addClipboardActivity(this.activity)
      .pipe(take(1), untilDestroyed(this)).subscribe(() => {
      if (!this.planningBoardClipboardService.clipboardVisible$.getValue()) {
        this.planningBoardClipboardService.toggleVisibility(true);
      }
    });
  }

  removeActivity() {
    this.confirmationService.openDialog(marker('Are you sure want to delete this activity?'))
      .pipe(
        filter((isConfirmed) => !!isConfirmed),
        take(1),
        switchMap(() => {
          this.progressService.start();
          return this.planningBoardClipboardService.deleteActivity(this.activity);
        }),
        finalize(() => this.progressService.complete()),
        untilDestroyed(this),
      ).subscribe(() => {
        this.activityRemoved.emit(this.activity.id);
        this.successNotification(marker('Activity deleted successfully'));
      });
  }

  updatePriority(priorityId: number) {
    if (priorityId === this.activity.priority.id) {
      return;
    }

    this.activity.priority = this.priorities.find((el) => el.id === priorityId);
    this.updateActivity(marker('Task priority successfully changed'));
  }

  durationChanged($event: string) {
    this.activity.duration = +$event;
    this.updateActivity(marker('Task duration successfully changed'));
  }

  updateTitle(value: string) {
    if (this.activity.description === value) {
      return;
    }
    this.activity.description = value;
    this.updateActivity(marker('Task description successfully changed'));
  }

  updateActivity(msg: string) {
    this.activitiesService.updateActivityByPk$(this.activity)
      .pipe(take(1))
      .subscribe(() => {
        this.planningBoardClipboardService.updateClipboardActivity(this.activity);
        if (!!msg) {
          this.successNotification(msg);
        }
      });
  }

  successNotification(msg: string) {
    this.notificationService.success({
      description: this.translate.instant(msg),
    });
  }

  goToActivityInMyDay() {
    this.cookie.put('selectedDate', JSON.stringify(this.activity.date));
    this.router.navigate(['/app/day-editor'], {
      queryParams: {
        activityId: this.activity.id,
      },
    });
  }
}
