import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { FetchActivitiesInClipboardCountForTaskByActivityGQL } from './graphql/fetchActivitiesInClipboardCountForTaskByActivityGQL';
import { UpdateActivityByPkGQL } from './graphql/updateActivityByPkGQL';
import { ActivityStatus, GroupId, ChangeTaskStatusActivity, UUID } from './planning-board.helper';
import { Activity } from '../../../../core/models/interfaces';
import { InsertActivityToTaskGQL } from './graphql/insertActivityToTaskGQL';
import { UpdateActivityStatusGQL } from './graphql/updateActivityStatusGQL';
import { AssignTaskToActivityGQL } from './graphql/assignTaskToActivityGQL';
import { FetchActivityStatusesGQL } from './graphql/fetchActivityStatusesGQL';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { MoveActivityInsideTaskGQL } from './graphql/moveActivityInsideTaskGQL';
import { MoveActivityToAnotherTaskGQL } from './graphql/moveActivityToAnotherTaskGQL';

export type ActivityID = number

type ActivityCountWithCorrespondingGroupAndTask = {
  data: {
    activities_aggregate: {
      aggregate: {
        count: number,
      },
    },
    tasks: TaskWithCorrespondingGroup[],
  },
};

type TaskWithCorrespondingGroup = {
  id: UUID,
  group: {
    id: GroupId,
  },
};

type TaskHaveActivitiesInClipboard = {taskId: UUID, groupId: number, haveActivities: boolean};

@Injectable({providedIn: 'root'})
export class ActivitiesService {
  private taskHaveActivitiesInClipboard$ = new BehaviorSubject<TaskHaveActivitiesInClipboard>(null);
  newActivityAssignedTask$ = new BehaviorSubject<{oldActivity: Activity, newActivity: Activity}>(null);

  constructor(
    private updateActivityByPkGQL: UpdateActivityByPkGQL,
    private insertActivityToTaskGQL: InsertActivityToTaskGQL,
    private assignTaskToActivityGQL: AssignTaskToActivityGQL,
    private fetchActivitiesInClipboardCountForTaskByActivityGQL: FetchActivitiesInClipboardCountForTaskByActivityGQL,
    private updateActivityStatusGQL: UpdateActivityStatusGQL,
    private fetchActivityStatusesGQL: FetchActivityStatusesGQL,
    private dataProviderService: DataproviderService,
    private moveActivityInsideTaskGQL: MoveActivityInsideTaskGQL,
    private moveActivityToAnotherTaskGQL: MoveActivityToAnotherTaskGQL,
  ) {
  }

  moveActivity$(
    id: number,
    order_in_task: number,
    task_id: GroupId | null,
  ): Observable<any> {
    if (!!task_id) {
      return this.moveActivityToAnotherTaskGQL.mutate({id, order_in_task, task_id});
    }
    return this.moveActivityInsideTaskGQL.mutate({id, order_in_task});
  }

  assignTaskToActivity(activityId: number, taskId: string) {
    return this.assignTaskToActivityGQL.mutate({id: activityId, task_id: taskId});
  }

  getTaskInClipboardValue$(): Observable<TaskHaveActivitiesInClipboard> {
    return this.taskHaveActivitiesInClipboard$;
  }

  insertActivityToTask(activity: Activity): Observable<any> {
    return this.insertActivityToTaskGQL.mutate({
      description: activity.description,
      duration: activity.duration,
      priorityId: activity.priority.id,
      task_id: activity.task.id,
    });
  }

  updateActivityByPk$(activity: Activity): Observable<ChangeTaskStatusActivity> {
    return this.updateActivityByPkGQL.mutate({
      id: activity.id,
      done: activity.done,
      in_clipboard: activity.in_clipboard,
      order_in_clipboard: activity.order_in_clipboard,
      order_in_phase: activity.order_in_phase,
      phaseId: activity.phase?.id || null,
      duration: activity.duration,
      description: activity.description,
      priorityId: activity.priority.id,
    }).pipe(
      tap(() => this.dataProviderService.updateActivityFront(activity)),
      map((res) => res['data']['update_activities_by_pk']),
    );
  }

  deleteActivityFromClipboardAndReturnCorrespondingTaskIfItShouldNotBeClipboard$(
    activity: Activity,
  ): Observable<any> {
    // set clipboard to false
    activity.in_clipboard = false;
    return  this.updateActivityByPk$(activity).pipe(
      // return task and group if it has activities in clipboard
      switchMap(() => this.getCorrespondingTaskIfNoActivitiesInClipboard$(activity)),
      // handle result of the function: if result is not null update task.clipboard = false
    );
  }

  getCorrespondingTaskIfNoActivitiesInClipboard$(activity: Activity): Observable<TaskWithCorrespondingGroup | null> {
    return this.fetchActivitiesInClipboardCountForTaskByActivityGQL.fetch({activity_id: activity.id}).pipe(
      map(
      (res: ActivityCountWithCorrespondingGroupAndTask) => {
        const activitiesAggregate = res.data.activities_aggregate;

        if (activitiesAggregate.aggregate.count > 0) {
          return res.data.tasks[0];
        }

        this.taskHaveActivitiesInClipboard$.next({
          taskId: activity.task.id,
          groupId: activity.task.group.id,
          haveActivities: false,
        });

        return null;
      },
    ));
  }

  // STATUSES
  fetchStatuses$(): Observable<ActivityStatus[]> {
    return this.fetchActivityStatusesGQL.watch({}, {fetchPolicy: 'no-cache'}).valueChanges.pipe(map(
      (res) => res['data']['statuses'],
    ));
  }

  updateStatus$(id: number, status: string): Observable<ChangeTaskStatusActivity> {
    return this.updateActivityStatusGQL.mutate({id, status}).pipe(map(
      (res) => res['data']['update_activities_by_pk'],
    ));
  }
}
