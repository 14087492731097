import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(
    private _notificationService: NotificationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          console.error(error);

          if (error.status === 401) {
            return;
          }

          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            if (error.error.message) {
              errorMessage = errorMessage + `\n${error.error.message}`;
            }
          }
          this._notificationService.error({title: 'HTTP Error!', description: errorMessage});

          return throwError(errorMessage);
        },
      ),
    );
  }
}
