import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class EwsAppointmentsGQL extends Query<any> {
  document = gql`
    query ewsAppointments ($date: String!, $timezone: String!) {
      ewsAppointments(date: $date, timezone: $timezone) {
        summary
        startTime
        extLink
        extId
        endTime
        allDay
      }
    }
  `;
}
