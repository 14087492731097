import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class InsertActivityToTaskGQL extends Mutation {
  document = gql`
    mutation MyMutation($description: String, $duration: Int, $priorityId: bigint, $task_id: uuid) {
      insert_activities_one(object: {done: false, description: $description, duration: $duration, in_clipboard: false, priorityId: $priorityId, task_id: $task_id}) {
        id
      }
    }
  `;
}
