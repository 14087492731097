import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Component({
  selector: 'app-topbar-global-actions',
  templateUrl: './topbar-global-actions.component.html',
  styleUrls: ['./topbar-global-actions.component.scss'],
})
export class TopbarGlobalActionsComponent implements OnInit {

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  get userName() {
    return this.authService.getUser.display_name;
  }
}
