import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export interface OnboardingTooltip {
  title: string;
  description: string;
}

export interface CookieConfigInterface {
  currentTooltipId: string;
  position: string;
}

export enum DayEditorOnboardingIdEnum {
  First = 'dayEditorTooltip-0',
  Second = 'dayEditorTooltip-1',
  Third = 'dayEditorTooltip-2',
  Fourth = 'dayEditorTooltip-3',
  Fifth = 'dayEditorTooltip-4',
  Seventh = 'dayEditorTooltip-6',
}

const dayEditorOnboardingTooltips: { [key in DayEditorOnboardingIdEnum]: OnboardingTooltip } = {
  [DayEditorOnboardingIdEnum.First]: {
    title: marker('My day page'),
    description: marker('Working with the activities plan. If not already happened, please select "My Day" from the main menu on the left side.'),
  },
  [DayEditorOnboardingIdEnum.Second]: {
    title: marker('Select needed day'),
    description: marker('To start you need to select the day, for which you want to plan. Please select the current day by clicking on the calendar bar or select the current day with the dot button on the right side.'),
  },
  [DayEditorOnboardingIdEnum.Third]: {
    title: marker('Week navigation'),
    description: marker('If you prefer to choose another day please select the day in the calendar bar. The arrow buttons on the right will help you to jump to the previous or next week.'),
  },
  [DayEditorOnboardingIdEnum.Fourth]: {
    title: marker('Day from calendar'),
    description: marker('You can also select the day by clicking on the calendar icon next to the shown month and a calendar date picker will pop up.'),
  },
  [DayEditorOnboardingIdEnum.Fifth]: {
    title: marker('Add new phase'),
    description: marker('When there is still no activities planned for the selected day, you will see a hint that “You have no phase today”. Let’s add a phase by clicking “Add new phase”, give it the name “Morning focus time” and give it a start time at 9:00 AM.'),
  },
  [DayEditorOnboardingIdEnum.Seventh]: {
    title: marker('Templates'),
    description: marker('This is a structure that eventually everyday repeats. Considering this, Planmy.day provides standard templates that one can prepare and select to build a day structure with basic activities already included.'),
  },
};

export function getDayEditorOnboardingTooltips(phasesListExist: boolean) {
  if (phasesListExist) {
    dayEditorOnboardingTooltips[DayEditorOnboardingIdEnum.Fifth].title = marker('Action for day');
    dayEditorOnboardingTooltips[DayEditorOnboardingIdEnum.Fifth].description = marker('To delete actions that we created by deleting all day, select "Delete day" three dots in the upper right corner of the daily plan. Now we are back to the empty day.');
  }
  return dayEditorOnboardingTooltips;
}
