import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class ToggleTaskExpandingGQL extends Mutation {
  document = gql`
  mutation ToggleTaskExpandingGQL(
    $id: uuid!,
    $is_expanded: Boolean
  ) {
    update_tasks_by_pk(
      pk_columns: {id: $id},
      _set: {
        is_expanded: $is_expanded
      }
    ) {
      id
    }
  }`;
}
