import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class FinishImplicitTaskGQL extends Mutation {
  document = gql`
  mutation FINISH_IMPLICIT_TASK_GQL(
    $id: String!,
    $unfinished_activities: String!
  ) {
    finishImplicitTask(
      id: $id,
      unfinishedActivities: $unfinished_activities
    ) {
      errors
      task {
        id
        status
        description
      }
      changedActivities {
        id
        done
        description
      }
    }
  }`;
}
