<ng-container *ngIf="!!activity">
  <li class="c-activityList__item" [cdkDragData]="activity" (cdkDragStarted)="cdkDragStarted($event)" cdkDrag>
    <ng-container *ngIf="parent !== 'container'">
      <div class="c-activityList__placeholder" *cdkDragPlaceholder></div>
    </ng-container>

    <div class="flex justify-between items-center" [ngStyle]="{'min-width.px': this.cdkDragPreviewWidth}" *cdkDragPreview>
      <div class="flex items-center">
        <button *ngIf="parent === 'container'" class="c-activityListEntry__checkbox"
                [class.checked]="activity.done"
                (click)="activity.done = !activity.done; updateActivity(null)">
          <mat-icon class="c-activityListEntry__checkboxIcon">done</mat-icon>
        </button>

        {{!!activity.description ? activity.description : 'Write your task...' | translate}}
      </div>

      <duration-entry [currentDuration]="activity.duration"></duration-entry>
    </div>

    <div class="c-activityListEntry" [class.cannotToClipboard]="parent === 'container' && activity.in_clipboard || !!activity.phase">
      <mat-icon *ngIf="!!activity.phase && parent === 'container'"
                (click)="goToActivityInMyDay()"
                [matTooltip]="('This activity is already scheduled, go to ' | translate) + activity.date"
                class="c-activityListEntry__planned">assignment_turned_in
      </mat-icon>

      <button *ngIf="parent === 'container'" class="c-activityListEntry__checkbox"
              [class.checked]="activity.done"
              (click)="activity.done = !activity.done; updateActivity(null)">
        <mat-icon class="c-activityListEntry__checkboxIcon">done</mat-icon>
      </button>


      <div class="c-activityListEntry__fields">
        <p class="c-activityListEntry__prioritiesItem {{activity.priority.description}}"
           [matMenuTriggerFor]="priorityDropdown">
          <span class="c-activityListEntry__prioritiesItemDot"></span>
        </p>
        <mat-menu #priorityDropdown="matMenu" xPosition="before" yPosition="above">
          <div class="c-activityListEntry__prioritiesWrapper">
            <p class="c-activityListEntry__prioritiesTitle">{{'Priority' | translate}}</p>
            <ul class="c-activityListEntry__priorities">
              <li *ngFor="let priority of priorities"
                  (click)="updatePriority(priority.id)"
                  class="c-activityListEntry__prioritiesItem {{priority.description}}"
                  [ngClass]="{'active': activity.priority.id === priority.id}">
                <span class="c-activityListEntry__prioritiesItemDot"></span>
              </li>
            </ul>
          </div>
        </mat-menu>

        <div class="c-activityListEntry__descriptionWrapper">
          <div class="flex-1 flex flex-col">
            <input type="text" class="c-activityListEntry__description"
                   [placeholder]="'Write your task...' | translate"
                   [value]="activity.description"
                   (blur)="updateTitle(activityDescription.value)"
                   (keyup.enter)="activityDescription.blur()" #activityDescription/>

            <activity-assigned-task *ngIf="parent === 'clipboard'"
                                    [activity]="activity"
                                    [groupsWithTasks]="groupsWithTasks"></activity-assigned-task>
          </div>
        </div>
        <activity-assigned-task *ngIf="parent === 'container'"
                                [activity]="activity"
                                [trigger]="assignedTaskTrigger"
                                [groupsWithTasks]="groupsWithTasks"></activity-assigned-task>

        <ng-template #assignedTaskTrigger>
          <p class="o-link o-link--currentColor">{{'Change assigned task' | translate}}</p>
        </ng-template>

        <duration-entry (durationChanged)="durationChanged($event)"
                        [currentDuration]="activity.duration"></duration-entry>
      </div>

      <button *ngIf="parent === 'container'"
              [matTooltip]="cannotToClipboardTooltip | translate"
              [class.cannotToClipboard]="parent === 'container' && activity.in_clipboard || !!activity.phase || activity.done"
              class="c-activityListEntry__iconBtn"
              mat-icon-button
              (click)="moveToClipboard()">
        <mat-icon>content_paste_go</mat-icon>
      </button>

      <button *ngIf="parent === 'clipboard'"
              [matTooltip]="'Remove from clipboard' | translate"
              (click)="removeFromClipboard()"
              class="c-activityListEntry__iconBtn"
              mat-icon-button>
        <mat-icon svgIcon="delete"></mat-icon>
      </button>

      <button [matMenuTriggerFor]="menu" class="c-activityListEntry__more">
        <mat-icon svgIcon="more-horiz"></mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button (click)="cloneActivity.emit(parent)" mat-menu-item>
          <mat-icon svgIcon="content-copy"></mat-icon>
          <span>{{'Clone activity' | translate}}</span>
        </button>
        <div (click)="$event.stopPropagation()">
          <div class="c-activityListEntry__prioritiesWrapper">
            <p class="c-activityListEntry__prioritiesTitle">{{'Priority' | translate}}</p>
            <ul class="c-activityListEntry__priorities">
              <li *ngFor="let priority of priorities"
                  (click)="updatePriority(priority.id)"
                  class="c-activityListEntry__prioritiesItem {{priority.description}}"
                  [ngClass]="{'active': activity.priority.id === priority.id}">
                <span class="c-activityListEntry__prioritiesItemDot"></span>
              </li>
            </ul>
          </div>
        </div>
        <button *ngIf="parent === 'container'" (click)="removeActivity()" mat-menu-item>
          <mat-icon svgIcon="delete"></mat-icon>
          <span>{{'Delete activity' | translate}}</span>
        </button>
        <button *ngIf="activity.in_clipboard && parent === 'container'"
                (click)="removeFromClipboard()"
                mat-menu-item>
          <mat-icon svgIcon="delete"></mat-icon>
          <span>{{'Remove from clipboard' | translate}}</span>
        </button>
      </mat-menu>
    </div>
  </li>
</ng-container>

