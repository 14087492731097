import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class DeleteActivityByPkGQL extends Mutation {
  document = gql`
    mutation MyMutation($id: bigint!, $is_deleted: Boolean = true) {
      update_activities_by_pk(pk_columns: {id: $id}, _set: {is_deleted: $is_deleted}) {
        id
      }
    }
  `;
}
