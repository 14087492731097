import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPasswordComponent } from './input-password/input-password.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { StaticNotificationComponent } from './static-notification/static-notification.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ThemeSwitcherComponent } from './theme-switcher/theme-switcher.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OnboardingTooltipsComponent } from './onboarding-tooltips/onboarding-tooltips.component';
import { MatRippleModule } from '@angular/material/core';
import { LanguageSelectionComponent } from './language-selection/language-selection.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { ActivityAssignedTaskComponent } from './activity-assigned-task/activity-assigned-task.component';
import { EntityStatusComponent } from './entity-status/entity-status.component';
import { DurationEntryComponent } from './duration-entry/duration-entry.component';

const OBJECTS_INSTANCES = [
  InputPasswordComponent,
  StaticNotificationComponent,
  SpinnerComponent,
  ThemeSwitcherComponent,
  OnboardingTooltipsComponent,
  LanguageSelectionComponent,
  ActivityAssignedTaskComponent,
];

@NgModule({
  declarations: [
    ...OBJECTS_INSTANCES,
    EntityStatusComponent,
    DurationEntryComponent,
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatIconModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatMenuModule,
        TranslateModule,
        MatRippleModule,
        FormsModule,
    ],
  exports: [
    ...OBJECTS_INSTANCES,
    EntityStatusComponent,
    DurationEntryComponent,
  ],
})
export class ObjectsModule {
}
