<mat-form-field [appearance]="appearance" class="{{classes}}">
  <mat-label>{{label | translate}}</mat-label>
  <input [type]="hideNewPass ? 'password' : 'text'"
         [formControl]="control"
         [autocomplete]="autoCompleteType"
         [placeholder]="placeholder | translate"
         ngDefaultControl
         inputMode="text"
         #pass matInput>
  <mat-icon matPrefix svgIcon="lock"></mat-icon>
  <mat-icon matSuffix (click)="hideNewPass = !hideNewPass" svgIcon="{{hideNewPass ? 'visibility-off' : 'visibility'}}"></mat-icon>
  <mat-hint align="end">{{pass.value?.length || 0}}/{{minPassLength}}</mat-hint>
  <mat-error>{{requiredError | translate}}</mat-error>
</mat-form-field>


