import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { HotToastRef } from '@ngneat/hot-toast';
import { CommonToastData } from './common-toast.model';

@Component({
  selector: 'app-common-toast',
  templateUrl: './common-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonToastComponent {
  constructor(
    @Inject(HotToastRef) public toastRef: HotToastRef<CommonToastData>,
  ) {}

  closeToast() {
    this.toastRef.close();
  }
}
