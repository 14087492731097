import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from '../../../../../core/models/interfaces';
import { ActivityManagementService } from '../../../../../core/services/activity-management.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'activity-entry-info-overlay',
  templateUrl: './activity-entry-info-overlay.component.html',
  styleUrls: ['./activity-entry-info-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivityEntryInfoOverlayComponent implements OnInit {
  @Input() activity!: Activity;

  constructor(
    private activityManagementService: ActivityManagementService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  }

  get note() {
    return this.sanitizer.bypassSecurityTrustHtml(this.activity.note);
  }

  openMoreInfo() {
    this.activityManagementService.setActivityData(this.activity);
  }
}
