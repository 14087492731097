import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, PartialObserver, Subject } from 'rxjs';
import { GraphqlService } from './graphql.service';
import { Activity, ActivityTask, Appointment, Phase, Priority, Template } from '../models/interfaces';
import { DateTime } from 'luxon';
import { PhaseSorter } from '../../_helpers/phase-sorter';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { ConfirmationService } from './confirmation.service';
import { TEMPLATE_DATE } from '../../_helpers/constants.helper';
import { NgProgressService } from 'src/app/shared/services/ng-progress.service';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { deepClone } from '../../_helpers/helpers';
import { PlanningBoardTask } from '../../modules/day-planner/components/planning-board/planning-board.helper';

interface DaysPk {
  date: string;
  templateId: number | undefined;
}

@Injectable({providedIn: 'root'})
export class DataproviderService {
  days: Map<string, Phase[]> = new Map();
  activities: Map<number, Activity> = new Map();
  templates: Template[] = [];

  // FIXME: days observable not necessary to pass the Map
  private _days$: BehaviorSubject<Map<string, Phase[]>> = new BehaviorSubject<Map<string, Phase[]>>(new Map<string, Phase[]>());
  private _activities$: BehaviorSubject<Activity[]> = new BehaviorSubject<Activity[]>([]);
  private _templates$: BehaviorSubject<Template[]> = new BehaviorSubject<Template[]>([]);
  public lastUpdatedAppointment$ = new Subject<Appointment>();

  constructor(
    private _graphqlService: GraphqlService,
    private _phaseSorter: PhaseSorter,
    private _authService: AuthService,
    private _notificationService: NotificationService,
    private _confirmationService: ConfirmationService,
    private progressService: NgProgressService,
    private translate: TranslateService,
  ) {
    const logoutSub$ = this._authService.logout$.subscribe((val) => {
      this.resetData();
      logoutSub$.unsubscribe();
    });
  }

  /**
   * Helper function to build the key for days map
   * @param date
   * @param templateId
   * @returns the map key
   */
  private buildDaysKey(date: string, templateId?: number): string {
    templateId === undefined ? templateId = -1 : templateId;
    return (date + templateId) as string;
  }

  /**
   * getting the phases from days map
   * @param date
   * @param templateId
   * @returns phases array
   */
  public getDayPhases(date: string, templateId?: number): Phase[] {
    if (this.hasDayPhases(date, templateId)) {
      return this.days.get(this.buildDaysKey(date, templateId))!;
    }
    return [];
  }

  private setDayPhases(date: string, phases: Phase[], templateId?: number) {
    this.days.set(this.buildDaysKey(date, templateId), phases);
  }

  private hasDayPhases(date: string, templateId?: number) {
    return this.days.has(this.buildDaysKey(date, templateId));
  }

  private deleteDayPhases(date: string, templateId?: number) {
    if (this.hasDayPhases(date, templateId)) {
      this.days.delete(this.buildDaysKey(date, templateId));
    }
  }

  public getActivitiesPerPhase$(phase: Phase): Observable<Activity[]> {
    return from(this.days.values()).pipe(
      filter((phases) => phases.length !== 0),
      map((phases) => phases.map((ph) => ph).filter((ph) => ph.id === phase.id)[0]),
      filter((ph) => ph !== undefined),
      map((ph) => ph.activities),
    );
  }

  subscribeToDays(observer: PartialObserver<any>) {
    return this._days$.subscribe(observer);
  }

  subscribeToActivities(observer: PartialObserver<any>) {
    return this._activities$.subscribe(observer);
  }


  refreshDays() {
    // FIXME: do not pass Map
    this._days$.next(new Map());
  }

  refreshActivities() {
    this._activities$.next(Array.from(this.activities.values()));
  }

  resetData() {
    this.activities = new Map();
    this.days = new Map();
    this.templates = [];
  }

  public changeDate(date: string) {
    this.fetchAll(date);
    this.refreshDays();
    this.refreshActivities();
  }

  private fetchAll(date: string, templateId?: number) {
    if (this.hasDayPhases(date) && this.getDayPhases(date)!.length > 0) {
      return;
    }

    templateId === undefined ? templateId = -1 : templateId;

    this._graphqlService
      .getActivitiesPerDate(date, templateId)
      .subscribe((result) => {
        const activities = JSON.parse(JSON.stringify(result?.data?.activities));

        if (activities !== undefined) {
          for (const activity of activities) {
            if (activity.fixedStart) {
              activity.fixedStart = DateTime.fromSQL(
                activity.date + ' ' + activity.fixedStart,
              );
            }
            activity.date = DateTime.fromSQL(activity.date);
            activity.id = +activity.id;
            this.activities.set(activity.id, activity);
          }
        }

        const phases = JSON.parse(JSON.stringify(result?.data?.phases));
        if (phases !== undefined) {
          for (const phase of phases) {
            if (phase.fixedStart) {
              phase.fixedStart = DateTime.fromSQL(
                date + ' ' + phase.fixedStart,
              );
            }
            phase.date = DateTime.fromSQL(phase.date);
            phase.id = +phase.id;
          }
        }

        if (phases.length > 0) {
          this.setDayPhases(date, phases, templateId);
          this.sortDay(date, templateId);
        }
        this.refreshDays();
      });
  }

  public sortDay(date: string, templateId?: number) {
    if (!this.hasDayPhases(date, templateId)) {
      return;
    }

    this._phaseSorter.sort(
      this.activities,
      this.getDayPhases(date, templateId),
    );
  }

  public moveActivityById(prevActivity: Activity, toActivity: Activity, dir: string): Observable<any> {
    return this._graphqlService.moveActivityById(prevActivity.id, toActivity.id, dir)
      .pipe(tap((result) => {
          const newActivity = deepClone(result.data.moveActivityById.activity);
          const changedActivities = result.data.moveActivityById.changedActivities;
          const date = newActivity.date;
          const templateId = newActivity.templateId;

          newActivity.fixedStart = !!newActivity.fixedStart ? DateTime.fromSQL(date + ' ' + newActivity.fixedStart) : null;
          newActivity.date = DateTime.fromSQL(newActivity.date);
          newActivity.id = +newActivity.id;
          newActivity.task = deepClone(prevActivity.task);

          if (prevActivity.in_clipboard) {
            newActivity.in_clipboard = false;
            newActivity.order_in_clipboard = null;
          }

          this.activities.set(newActivity.id, newActivity);
          // updating the changed activities in the activity list
          this.updateChangedActivities(changedActivities);
          this.sortDay(date, templateId);
          this.refreshDays();
        }, (error) => {
          this._notificationService.error({
            description: this.translate.instant(marker('Failed to move activity')),
          });
        }),
      );
  }

  updateChangedActivities(changedActivities) {
    if (changedActivities === null) {
      return;
    }

    for (const changedAct of changedActivities) {
      const act = this.activities.get(+changedAct.i);
      if (!act) {
        return;
      }

      if (changedAct.n !== -1) {
        act.nextActivityId = +changedAct.n;
      }
      if (changedAct.p !== -1) {
        act.previousActivityId = +changedAct.p;
      }
      if (changedAct.ph !== -1) {
        act.phase = this.getPhase(changedAct.ph);
      }
      if (!!changedAct.date) {
        act.date = DateTime.fromSQL(changedAct.date);
      }
    }
  }

  getPhase(phaseId: number): Phase | undefined {
    for (const value of this.days.values()) {
      for (const phase of value) {
        if (phase.id === phaseId) {
          return phase;
        }
      }
    }
    return undefined;
  }

  getDefaultTemplate() {
    return this.templates.filter(
      (t) => t.active === true && t.default === true,
    )[0];
  }

  // CHECK: what is better to get results from GQL requests - with JSON.parse or without?
  public deleteActivity(activity: Activity) {
    return this._confirmationService.openDialog()
      .pipe(
        filter((confirmed) => confirmed),
        switchMap(() => {
          this.progressService.start();
          if (!activity.task || !!activity.task?.is_implicit) {
            return this._graphqlService.deleteActivityFromList(activity.id);
          }
          return this._graphqlService.removeActivityFromList(activity.id);
        }),
        tap((result) => {
            if (activity.appointment) {
              activity.appointment.planned = false;
              this.lastUpdatedAppointment$.next(activity.appointment);
            }

            this.activities.delete(+activity.id);

            const templateId = activity.templateId;

            // need to receive the changed activity ids as well
            const changedActivities = result.changedActivities;
            this.updateChangedActivities(changedActivities);
            this.sortDay(activity.date.toFormat('yyyy-MM-dd'), templateId);
            this.refreshDays();
            this._notificationService.success({
              description: this.translate.instant(marker('Successfully deleted activity')),
            });
          },
          (error) => {
            this._notificationService.error({
              description: this.translate.instant(marker('Failed to delete activity')),
            });
          }),
        finalize(() => this.progressService.complete()),
      );
  }

  public pullActivityFromPhase(activity: Activity) {
    return this._graphqlService.removeActivityFromList(activity.id)
      .pipe(tap((result) => {
        this.activities.delete(+activity.id);
        
        const changedActivities = result.changedActivities;
        this.updateChangedActivities(changedActivities);
        this.sortDay(activity.date.toFormat('yyyy-MM-dd'), activity.templateId);
        this.refreshDays();
      }, (error) => {
        this._notificationService.error({
          description: this.translate.instant(marker('Failed to pull activity')),
        });
      }
      ))
  }

  public cloneActivity(activity: Activity) {
    return this._graphqlService.cloneActivity(activity.id).pipe(
      tap(
        (result: Activity) => {
          const act = result.data.cloneActivity.activity;
          if (act.fixedStart) {
            act.fixedStart = DateTime.fromSQL(act.date + ' ' + act.fixedStart);
          }
          act.date = DateTime.fromSQL(act.date);
          act.id = +act.id;
          if (act.templateId !== undefined) {
            act.templateId = +act.templateId;
          }
          if (!!activity.task) {
            act.task = activity.task;
          }
          this.activities.set(+act.id, act);
          this.refreshActivities();

          // need to receive the changed activity ids as well
          const changedActivities = result.data.cloneActivity.changedActivities;
          this.updateChangedActivities(changedActivities);

          this.sortDay(act.date.toFormat('yyyy-MM-dd'), act.templateId);
          this.refreshDays();
        },
        (error) => {
          this._notificationService.error({
            description: this.translate.instant(marker('Failed to clone activity')),
          });
        }),
    );
  }

  public addNewActivityToPhase(phase: Phase, description: string, duration: number, fixedStart: DateTime) {
    return this._graphqlService.newActivityToPhase(phase.date, description, duration, phase.id, fixedStart)
      .pipe(
        tap((result: Activity) => {
            const act = result.data.newActivityToPhase.activity;
            act.date = DateTime.fromSQL(act.date);
            act.id = +act.id;
            if (act.fixedStart) {
              act.fixedStart = DateTime.fromSQL(act.fixedStart);
            }
            this.activities.set(+act.id, act);

            const templateId = phase.templateId;

            // need to receive the changed activity ids as well
            const changedActivities = result.data.newActivityToPhase.changedActivities;
            this.updateChangedActivities(changedActivities);
            this.sortDay(act.date.toFormat('yyyy-MM-dd'), templateId);
            this.refreshDays();
          },
          (error) => {
            this._notificationService.error({
              description: this.translate.instant(marker('Failed to add activity')),
            });
          }),
      );
  }

  public updateActivityObserver(activity: Activity) {
    this.progressService.start();
    return this._graphqlService.updateActivity(activity).pipe(
      tap((result: Activity) => {
          this.sortDay(activity.date.toFormat('yyyy-MM-dd'), activity.templateId);
          this.refreshDays();
        },
        (error) => {
          this._notificationService.error({
            description: this.translate.instant('Failed to update activity'),
          });
        }),
      finalize(() => this.progressService.complete()),
    );
  }

  public updateActivityFront(activity: Activity) {
    const activities = Array.from(this.activities.values());
    const activityForUpdate = activities.find((el) => el.id === activity.id);

    if (!activityForUpdate) {
      return;
    }

    activityForUpdate.duration = activity.duration;
    activityForUpdate.description = activity.description;
    activityForUpdate.priority = activity.priority;
    activityForUpdate.task = activity.task;
    activityForUpdate.done = activity.done;

    this.sortDay(activity.date, activity.templateId);
    this.refreshDays();
  }

  public createPhase(
    date: string,
    name: string,
    fixedStart: string,
    templateId?: number,
  ) {
    return this._graphqlService.createPhase(date, name, fixedStart, templateId)
      .pipe(
        tap((result: Phase) => {
            const phase = result.data.createPhase.phase;
            const activity = result.data.createPhase.activity;
            const changedActivities = result.data.createPhase.changedActivities;

            if (phase.fixedStart) {
              phase.fixedStart = DateTime.fromSQL(
                phase.date + ' ' + phase.fixedStart,
              );
            }
            phase.date = DateTime.fromSQL(phase.date);
            phase.id = +phase.id;
            const phases = this.getDayPhases(date, templateId);
            phases.push(phase);
            this.setDayPhases(date, phases, templateId);

            activity.date = DateTime.fromSQL(activity.date);
            activity.phaseDefault = activity.phaseDefault as boolean;

            this.activities.set(+activity.id, activity);

            this.updateChangedActivities(changedActivities);
            this.sortDay(date, templateId);
            this.refreshDays();
            this._notificationService.success({
              description: this.translate.instant(marker('Successfully created phase')),
            });
          },
          (error) => {
            this._notificationService.error({
              description: this.translate.instant(marker('Failed to create phase')),
            });
          }),
      );
  }

  public updatePhase(phase: Phase) {
    return this._graphqlService.updatePhase(phase.id, phase.fixedStart, phase.name)
      .pipe(
        tap(() => {
            this.sortDay(phase.date.toFormat('yyyy-MM-dd'), phase.templateId);
            this.refreshDays();
          },
          (error) => {
            this._notificationService.error({
              description: this.translate.instant(marker('Failed to update phase')),
            });
          }),
      );
  }

  public deletePhase(phase: Phase) {
    const deletePhaseSub = this._confirmationService.openDialog(
      this.translate.instant(marker('Are you sure you want to delete the phase?')),
    ).pipe(filter((confirmed) => confirmed))
      .subscribe(() => {
        this._graphqlService.deletePhase(phase.id).subscribe((result: Phase) => {
            const removedActivities = result.data.deletePhase.removedActivities;
            const changedActivities = result.data.deletePhase.changedActivities;

            const templateId = phase.templateId;

            let phases = this.getDayPhases(
              phase.date.toFormat('yyyy-MM-dd'),
              templateId,
            );
            phases = phases.filter((ph) => ph.id !== phase.id);
            this.setDayPhases(phase.date.toFormat('yyyy-MM-dd'), phases, templateId);

            for (const act of removedActivities) {
              this.activities.delete(+act.id);
            }

            this.updateChangedActivities(changedActivities);
            this.sortDay(phase.date.toFormat('yyyy-MM-dd'), templateId);
            this.refreshDays();
            this._notificationService.success({
              description: this.translate.instant(marker('Successfully deleted phase')),
            });
            deletePhaseSub.unsubscribe();
          },
          (error) => {
            this._notificationService.error({
              description: this.translate.instant(marker('Failed to delete phase')),
            });
          },
        );
      });
  }

  // *********************************
  // Template related stuff
  // *********************************

  public subscribeToTemplates(observer: PartialObserver<any>) {
    return this._templates$.subscribe(observer);
  }

  public refreshTemplates() {
    this._templates$.next(this.templates);
  }

  public changeTemplate(date: string, templateId: number) {
    this.fetchAll(date, templateId);
    this.refreshDays();
  }

  public getTemplates() {
    return this._graphqlService.getTemplates()
      .pipe(tap((result) => {
        this.templates = JSON.parse(JSON.stringify(result.data.templates));
        this._templates$.next(this.templates);
      }));
  }

  public insertNewDayFromTemplate(templateId: number, date: string): Observable<any> {
    this.progressService.start();
    return this._graphqlService.insertNewDayFromTemplate(templateId, date).pipe(
      tap(() => {
          this.fetchAll(date);
          this.sortDay(date);
          this.refreshDays();
          this._notificationService.success({
            description: this.translate.instant(marker('Successfully inserted new day')),
          });
        },
        (error) => {
          this._notificationService.error({
            description: this.translate.instant(marker('Failed to insert new day')),
          });
        }),
      finalize(() => this.progressService.complete()),
    );
  }

  public createTemplate(name: string, active: boolean): Observable<any> {
    return this._graphqlService.createTemplate(name, active).pipe(
      tap(
        (result) => {
          const template = result.data.createTemplate.template;
          this.templates.push(template);
          this.refreshTemplates();
          this._notificationService.success({
            description: this.translate.instant(marker('Successfully created template')),
          });
        },
        (error) => {
          this._notificationService.error({
            description: this.translate.instant(marker('Failed to create template')),
          });
        },
      ),
    );
  }

  public cloneTemplate(id: number, name: string): Observable<any> {
    return this._graphqlService.cloneTemplate(id, name).pipe(
      tap((result) => {
          const template = result.data.cloneTemplate.template;
          this.templates.push(template);
          this.refreshTemplates();
          this._notificationService.success({
            description: this.translate.instant(marker('Successfully cloned template')),
          });
        },
        (error) => {
          this._notificationService.error({
            description: this.translate.instant(marker('Failed to clone template')),
          });
        },
      ),
    );
  }

  public updateTemplate(templateId: number, name: string, active: boolean) {
    return this._graphqlService.updateTemplate(templateId, name, active).pipe(
      tap(() => {
        const template = this.templates.find((tpl) => tpl.id === templateId);
        template.name = name;
        template.active = active;
        template.default = false;
        this.refreshTemplates();
      }, (error) => {
        this._notificationService.error({
          description: this.translate.instant(marker('Failed to update template')),
        });
      }),
    );
  }

  public deleteTemplate(id: number) {
    return this._confirmationService.openDialog()
      .pipe(
        filter((confirmed) => !!confirmed),
        switchMap(() => this._graphqlService.deleteTemplate(id)),
        tap(() => {
          this.templates = this.templates.filter((tpl) => tpl.id !== id);
          const phases = this.getDayPhases(TEMPLATE_DATE, id);
          phases?.map((phase) => this.deleteAllActivitiesFromPhase(phase));
          this.deleteDayPhases(TEMPLATE_DATE, id);
          this.refreshDays();
          this.refreshTemplates();
        }),
      );
  }

  public deleteDay(date: string) {
    return this._confirmationService.openDialog()
      .pipe(
        filter((confirmed) => !!confirmed),
        switchMap(() => {
          this.progressService.start();
          return this._graphqlService.deleteDay(date);
        }),
        tap(() => {
          const phases = this.getDayPhases(date);
          phases?.map((phase) => this.deleteAllActivitiesFromPhase(phase));
          this.deleteDayPhases(date);
          this.refreshDays();
          this.progressService.complete();
        }),
      );
  }

  private deleteAllActivitiesFromPhase(phase: Phase) {
    phase.activities.forEach((activity) =>
      this.activities.delete(activity.id),
    );
  }

  // ************************************
  // Appointment related stuff
  // ************************************

  public appointmentToActivityList(appointment: Appointment) {
    if (appointment.allDay) {
      return this._notificationService.warning({
        description: this.translate.instant(marker('All day appointments cannot be added to activities')),
      });
    }

    const date = appointment.startTime.toFormat('yyyy-MM-dd');
    if (!this.hasDayPhases(date)) {
      return this._notificationService.warning({
        description: this.translate.instant(marker('Need to add a day first - click "Add New Day"')),
      });
    }

    if (appointment.planned) {
      return this._notificationService.warning({
        description: this.translate.instant(marker('The appointment is already inserted')),
      });
    }
    // get the extId from appointment
    // check if in activities for the current day are appointments related that have the extId

    const toInsert = this.findAppoinmentInsertPosition(
      appointment.startTime,
      this.getDayPhases(date),
    );

    this.progressService.start();
    const createAppointmentSub = this._graphqlService.createAppointment(appointment, toInsert.dir, toInsert.insertToId!).subscribe(
      (result) => {
        appointment.planned = true;
        const act = result.data.createAppointment.activity;
        if (act.fixedStart) {
          act.fixedStart = DateTime.fromSQL(act.date + ' ' + act.fixedStart);
        }
        act.date = DateTime.fromSQL(act.date);
        act.id = +act.id;
        act.done = false;
        if (act.templateId !== undefined) {
          act.templateId = +act.templateId;
        }
        this.activities.set(+act.id, act);
        // TODO: to get also the appointment data
        this.refreshActivities();

        // need to receive the changed activity ids as well
        const changedActivities = result.data.createAppointment.changedActivities;
        this.updateChangedActivities(changedActivities);

        this.sortDay(act.date.toFormat('yyyy-MM-dd'), act.templateId);
        this.refreshDays();
        this.progressService.complete();
      }, (error) => {
        this._notificationService.error({
          description: this.translate.instant(marker('Failed to add appointment to activities')),
        });
      }, () => createAppointmentSub.unsubscribe(),
    );
  }

  /**
   * finding the right insert position for the appointment
   * (given the start time) in the activity lists of phases
   * TODO: implement test spec
   * @param startTime the start time of the appointment
   * @param phases list of phases to search insert position
   * @returns insert direction and activity
   */
  private findAppoinmentInsertPosition(startTime: DateTime, phases: Phase[]) {
    const date = startTime.toFormat('yyyy-MM-dd');
    let found: boolean = false;
    let current;
    let insertToId;
    for (const phase of phases) {
      for (const activity of phase.activities) {
        current = activity;
        if (!found && startTime <= activity.actStart!) {
          if (current.previousActivityId === undefined || current.phaseDefault && startTime.equals(activity.actStart!)) {
            insertToId = current.id;
          } else {
            insertToId = current.previousActivityId;
          }
          found = true;
        }
      }
    }

    if (!found) {
      insertToId = current?.id!;
    }

    return {insertToId, dir: 'AFTER'};
  }
}
