import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Activity, Phase, Priority, Template } from '../../../../core/models/interfaces';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { switchMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cdkDragMove, DropDirectionState, getDirectionState } from 'src/app/_helpers/helpers';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GoogleContactsInterface } from '../../../google/services/google-contacts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityManagementService } from '../../../../core/services/activity-management.service';
import { ActivitiesService } from '../planning-board/activities.service';
import { of } from 'rxjs';
import { ActivityStatus, AssignedGroupContainer } from '../planning-board/planning-board.helper';

// the component holds all sorted activities per date
@UntilDestroy()
@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
})
export class ActivityListComponent implements OnInit, AfterViewInit {
  @Input() phase!: Phase;
  @Input() peopleContacts: GoogleContactsInterface[] = [];
  @Input() template: Template;
  @Input() date!: string;
  @Input() priorityPainterClass!: string;
  @Input() dayEditor: boolean = true;
  @Input() googleContactsEnabled: boolean = false;
  cdkDragMove = cdkDragMove;

  activities: Activity[] = [];
  @Input() priorities: Priority[] = [];
  @Input() groupsWithTasks: AssignedGroupContainer[];
  @Input() activityStatuses: ActivityStatus[];

  constructor(
    private dataProviderService: DataproviderService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private activityManagementService: ActivityManagementService,
    private route: ActivatedRoute,
    private activitiesService: ActivitiesService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.dataProviderService.subscribeToDays({
      next: () => this.refreshData(),
    });
  }

  ngAfterViewInit() {
    this.showActivityManagementBlockIfHaveQueryParams();
  }

  refreshData() {
    this.dataProviderService.getActivitiesPerPhase$(this.phase)
      .pipe(untilDestroyed(this))
      .subscribe((activities) => {
        this.activities = activities.filter((activity) => !activity.phaseDefault);
      });
  }

  showActivityManagementBlockIfHaveQueryParams() {
    const activityIdFromParams = this.route.snapshot.queryParamMap.get('activityId');

    if (!!activityIdFromParams) {
      const foundedActivityById = this.activities.find((el) => +el.id === +activityIdFromParams);

      if (!foundedActivityById) {
        return;
      }

      this.activityManagementService.setActivityData(foundedActivityById);
    }
    this.cdr.detectChanges();
  }

  addActivity() {
    const activityDuration = 15;
    this.dataProviderService.addNewActivityToPhase(
      this.phase,
      '',
      activityDuration,
      null,
    ).pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        this.notificationService.success({
          title: this.translate.instant(marker('Keep it up...')),
          description: this.translate.instant(marker('Activity added successfully')),
        });
      });
  }

  drop(event: CdkDragDrop<Activity[]>) {
    this.cdkDragMove.next(true);

    if (event.previousContainer === event.container && event.currentIndex === event.previousIndex || this.phase === undefined) {
      return;
    }

    let prevActivity = event.previousContainer.data[event.previousIndex];
    let toActivity;
    let dir: DropDirectionState = DropDirectionState.After;

    if (event.previousContainer === event.container) {
      dir = getDirectionState(event);
      prevActivity = event.container.data[event.previousIndex];
      toActivity = event.container.data[event.currentIndex];

      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (event.currentIndex === 0) {
        const actPhaseDefault = this.phase?.activities.filter((activity) => !!activity.phaseDefault).pop();
        if (actPhaseDefault) {
          toActivity = actPhaseDefault;
        }
      } else {
        toActivity = event.container.data[event.currentIndex - 1];
      }

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    this.dataProviderService.moveActivityById(
      prevActivity,
      toActivity,
      dir).pipe(
      take(1),
      switchMap((res) => {
        if (prevActivity.in_clipboard === true) {
          prevActivity.in_clipboard = false;
          prevActivity.phase = this.phase;
          prevActivity.order_in_clipboard = null;
          return this.activitiesService.updateActivityByPk$(prevActivity);
        } else {
          return of(res);
        }
      }),
      untilDestroyed(this),
    ).subscribe(() => {
    }, () => {
      this.resetActivitiesSorting(event);
    });
  }

  private resetActivitiesSorting(event: CdkDragDrop<Activity[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.activities, event.currentIndex, event.previousIndex);
    } else {
      transferArrayItem(
        event.container.data,
        event.previousContainer.data,
        event.currentIndex,
        event.previousIndex,
      );
    }
  }
}
