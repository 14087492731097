import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Activity } from '../models/interfaces';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class ActivityManagementService {
  private _activityData$: BehaviorSubject<Activity> = new BehaviorSubject<Activity>(null);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  get activityData$(): Observable<Activity> {
    return this._activityData$;
  }

  setActivityData(activity: Activity | null) {
    this._activityData$.next(activity);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {activityId: activity !== null ? activity.id : null},
      queryParamsHandling: 'merge',
    });
  }
}
