import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class CheckEwsConfigGQL extends Query<any> {
  document = gql`
    query ewsCheckConfig($url: String!, $user: String!, $password: String!, $exchange_version: String!) {
      ewsCheckConfig (url: $url, user: $user, password: $password, exchange_version: $exchange_version)
    }
  `;
}
