<button
  (click)="btnClickEmit()"
  type="button"
  [disabled]="disabled"
  [type]="type"
  [ngClass]="{'cursor-not-allowed': loading}"
  class="btn {{BTN_COLORS[btnColor]}} {{BTN_STYLES[btnStyle]}}"
  matRipple>
  <ng-container *ngIf="!loading; else btnLoading">
    <ng-content></ng-content>
  </ng-container>
  <ng-template #btnLoading>
    <img src="/assets/img/icons/spinner.png" alt="" class="btn__spinner">
  </ng-template>
</button>
