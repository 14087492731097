<div *ngIf="!!activeStatus" class="c-activityStatus">
  <p class="c-activityStatus__title"
     [ngClass]="{'todo' : !!activeStatus.is_new, 'done' : !!activeStatus.is_resolved, 'canceled': activeStatus.key === 'CANCELED'}"
     [matMenuTriggerFor]="statusesMenu">{{activeStatus.key}} <mat-icon class="c-activityStatus__titleIcon">expand_more</mat-icon></p>

  <mat-menu #statusesMenu="matMenu" xPosition="before" class="c-activityStatus__list">
    <ng-container *ngFor="let status of statuses">
      <button *ngIf="status.key !== activeStatus.key"
        (click)="updateStatus.emit(status.key)" mat-menu-item>
        <span class="c-activityStatus__listItem" [ngClass]="{'todo' : !!status.is_new, 'done' : !!status.is_resolved, 'canceled': status.key === 'CANCELED'}">
          {{status.key}}
        </span>
      </button>
    </ng-container>
  </mat-menu>
</div>

