import { Component, HostListener, Input } from '@angular/core';
import { CookieConfigInterface, DayEditorOnboardingIdEnum, OnboardingTooltip } from './onboarding-tooltips.helper';
import { CookieService } from 'ngx-cookie';
import { debounce } from '../../../../_helpers/helpers';

const FinishedStep = '-1';

@Component({
  selector: 'onboarding-tooltips',
  templateUrl: './onboarding-tooltips.component.html',
  styleUrls: ['./onboarding-tooltips.component.scss'],
})
export class OnboardingTooltipsComponent {
  _onboardingTooltips: { [key in DayEditorOnboardingIdEnum]: OnboardingTooltip } = null;
  firstTooltipId: string = '';
  currentStepId: string = '';
  currentStepNumber: number = 0;
  lastStepNumber: number = null;
  position: string = '';
  visible: boolean = false;
  currentTooltipData: OnboardingTooltip = null;

  @Input() get onboardingTooltips(): { [key in DayEditorOnboardingIdEnum]: OnboardingTooltip } {
    return this._onboardingTooltips;
  }

  set onboardingTooltips(tooltips: { [key in DayEditorOnboardingIdEnum]: OnboardingTooltip }) {
    this._onboardingTooltips = tooltips;

    if (Object.keys(this._onboardingTooltips).length) {
      this.firstTooltipId = Object.keys(this._onboardingTooltips)[0];
      const cookieConfig: CookieConfigInterface = JSON.parse(this.cookie.get(this.firstTooltipId) || 'null');
      this.currentStepId = cookieConfig?.currentTooltipId || this.firstTooltipId;
      this.currentStepNumber = Object.keys(this._onboardingTooltips).findIndex((index) => index === this.currentStepId);
      this.lastStepNumber = Object.keys(this._onboardingTooltips).length - 1;
      this.position = cookieConfig?.position;
      this.currentTooltipData = this._onboardingTooltips[this.firstTooltipId];
      this.visible = cookieConfig?.currentTooltipId !== FinishedStep && !!this.currentTooltipData;
      const firstPositionSetting = setTimeout(() => {
        this.setPosition();
        clearTimeout(firstPositionSetting);
      }, 15);
    }
  }

  @HostListener('window:resize', ['$event'])
  @debounce()
  onResize() {
    this.setPosition();
  }

  constructor(
    private cookie: CookieService,
  ) {
  }

  setPosition() {
    this.currentTooltipData = this._onboardingTooltips[this.currentStepId];
    const pointerForTooltip = document.getElementById(this.currentStepId);
    if (!!pointerForTooltip) {
      const setPositionTimeout = setTimeout(() => {
        this.initPosition(pointerForTooltip);
        this.setCookieConfigBody(this.currentStepId, this.position);
        clearTimeout(setPositionTimeout);
      },15);
    } else {
      this.finish();
    }
  }

  initPosition(pointerForTooltip: HTMLElement) {
    const onboardingTooltipsEl = document.getElementById('onboardingTooltips');
    const onboardingTooltipsGoesBeyondRight = window.innerWidth - pointerForTooltip.getBoundingClientRect().right < onboardingTooltipsEl.offsetWidth;
    const onboardingTooltipsGoesBeyondBottom = window.innerHeight - pointerForTooltip.getBoundingClientRect().bottom < onboardingTooltipsEl.offsetHeight;

    const positionLeft = onboardingTooltipsGoesBeyondRight
      ? window.innerWidth - (window.innerWidth - pointerForTooltip.getBoundingClientRect().right) - onboardingTooltipsEl.offsetWidth - 35
      : pointerForTooltip.getBoundingClientRect().left + (pointerForTooltip.offsetWidth > 50 ? 15 : -5);

    const positionTop = onboardingTooltipsGoesBeyondBottom
      ? window.innerHeight - (window.innerHeight - pointerForTooltip.getBoundingClientRect().bottom) - (onboardingTooltipsEl.offsetHeight + pointerForTooltip.offsetHeight + 15)
      : pointerForTooltip.getBoundingClientRect().top + 50;

    this.position = `transform: translateX(${positionLeft}px) translateY(${positionTop}px);`;

    onboardingTooltipsGoesBeyondRight ? onboardingTooltipsEl.classList.add('right') : onboardingTooltipsEl.classList.remove('right');
    onboardingTooltipsGoesBeyondBottom ? onboardingTooltipsEl.classList.add('bottom') : onboardingTooltipsEl.classList.remove('bottom');
  }

  public prevStep() {
    this.currentStepNumber--;
    this.currentStepId = Object.keys(this._onboardingTooltips)[this.currentStepNumber];
    this.setPosition();
  }

  public nextStep() {
    this.currentStepNumber++;
    this.currentStepId = Object.keys(this._onboardingTooltips)[this.currentStepNumber];
    this.setPosition();
  }

  public finish() {
    this.visible = false;
    this.setCookieConfigBody(FinishedStep, this.position);
  }

  private setCookieConfigBody(stepId: string, position: string) {
    const cookieConfigBody: CookieConfigInterface = {
      currentTooltipId: stepId,
      position: position,
    };
    this.cookie.put(this.firstTooltipId, JSON.stringify(cookieConfigBody));
  }
}
