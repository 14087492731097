import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AppButtonComponent } from '../core/components/app-button/app-button.component';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { CommonToastComponent } from './components/toasts/common-toast/common-toast.component';
import { PrivacyPolicyComponent } from './info-pages/privacy-policy/privacy-policy.component';
import { PlanningBoardActivityComponent } from '../modules/day-planner/components/planning-board/planning-board-activity/planning-board-activity.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { DayPlannerModule } from '../modules/day-planner/day-planner.module';
import { ObjectsModule } from '../core/components/objects/objects.module';
import { MatTooltipModule } from '@angular/material/tooltip';

const SHARED_INSTANCES = [
  AppButtonComponent,
  DateTimeFormatPipe,
  SearchFilterPipe,
  ConfirmationDialogComponent,
  CommonToastComponent,
  PlanningBoardActivityComponent,
];

@NgModule({
  declarations: [
    ...SHARED_INSTANCES,
    PrivacyPolicyComponent,
  ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatRippleModule,
        TranslateModule,
        MatIconModule,
        MatMenuModule,
        DragDropModule,
        FormsModule,
        ObjectsModule,
        MatTooltipModule,
    ],
  exports: [
    ...SHARED_INSTANCES,
  ],
  providers: [],
})
export class SharedModule {
}
