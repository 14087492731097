import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class UpdateUserDisplayNameGQL extends Mutation {
  document = gql`
    mutation MyMutation($id: uuid = "", $display_name: String = "") {
      update_users_by_pk(pk_columns: {id: $id}, _set: {display_name: $display_name}) {
        display_name
      }
    }
  `
}
