import { Injectable } from '@angular/core';
import { BehaviorSubject, PartialObserver, Observable, combineLatest, of } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { GlobalSettingsKeys } from '../models/enums';
import { EwsConfig, Priority } from '../models/interfaces';
import { DataproviderService } from './dataprovider.service';
import { GraphqlService } from './graphql.service';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { untilDestroyed } from '@ngneat/until-destroy';
import { MsalService } from '@azure/msal-angular';
import { ExchangeOnlineCalendarService } from '../../modules/microsoft/services/exchange-online-calendar.service';

@Injectable({providedIn: 'root'})
export class AppSettingsService {
  private globalSettings: Map<string, string> = new Map();

  private priorities: Priority[];
  private _priorities$: BehaviorSubject<Priority[]> = new BehaviorSubject<Priority[]>([]);

  private ewsConfig: EwsConfig = {id: undefined, ews_url: '', ews_user: '', ews_password: '', ews_exchange_version: ''};
  private _ewsConfig$: BehaviorSubject<EwsConfig> = new BehaviorSubject<EwsConfig>(this.ewsConfig);

  private ewsVersions: string[];
  private _ewsVersions$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  private _googleEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _googleCalEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _googleContactsEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _exchangeOnlineEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _ewsCalEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _caldavEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _graphqlService: GraphqlService,
    private _authService: AuthService,
    private _dataProviderService: DataproviderService,
    private msalService: MsalService,
    private exchangeOnlineCalendarService: ExchangeOnlineCalendarService,
  ) {
    const logoutSub = this._authService.logout$.subscribe(() => {
      this.initDataStructures();
      logoutSub.unsubscribe();
    });
    const loginSub = this._authService.login$
      .pipe(switchMap(() => this.initGlobalSettings()))
      .subscribe(() => loginSub.unsubscribe());
  }

  private initDataStructures() {
    this.globalSettings = new Map();
    this.priorities = [];
    this.ewsConfig = {id: undefined, ews_url: '', ews_user: '', ews_password: '', ews_exchange_version: ''};
    this.ewsVersions = [];
  }

  public initGlobalSettings() {
    return combineLatest([
      this.loadGlobalSettings(),
      this.getPriorities(),
      this._dataProviderService.getTemplates(),
      this.getEwsConfig(),
      this.getEwsExchangeVersions(),
      this.getMsalCacheData(),
    ]);
  }

  getMsalCacheData(): Observable<any> {
    const account = this.msalService.instance.getAllAccounts()[0];

    if (account) {
      const accessTokenRequest = {
        scopes: ['profile'],
        account: account,
      };
      return this.isExchangeOnlineCalEnabled$().pipe(
        filter((enabled) => enabled),
        switchMap(() => this.msalService.acquireTokenSilent(accessTokenRequest)),
        tap((res) => this.exchangeOnlineCalendarService.setExchangeOnlineJwtData(res)),
      );
    } else {
      return of(null);
    }
  }


  /*****************************/
  // EWS config relevant stuff
  /*****************************/

  public subscribeToEwsConfig(observer: PartialObserver<any>) {
    return this._ewsConfig$.subscribe(observer);
  }

  public ewsConfig$() {
    return this._ewsConfig$;
  }

  public getEwsConfig() {
    return this._graphqlService.getEwsConfig().pipe(tap((result) => {
      this.ewsConfig = JSON.parse(JSON.stringify(result.data.ews_config))[0];
      this._ewsConfig$.next(this.ewsConfig);
    }));
  }

  public setEwsConfig(ewsConfig: EwsConfig) {
    if (ewsConfig.id === undefined) {
      this._graphqlService.insertEwsConfig(ewsConfig).subscribe();
    } else {
      this._graphqlService.updateEwsConfig(ewsConfig).subscribe();
    }
  }

  public subscribeToEwsVersions(observer: PartialObserver<any>) {
    this._ewsVersions$.subscribe(observer);
  }

  public getEwsExchangeVersions(): Observable<any> {
    return this._graphqlService.getEwsExchangeVersions().pipe(tap((result) => {
      const versions = JSON.parse(JSON.stringify(result.data.ews_exchange_versions));
      this.ewsVersions = versions.map((v) => v.version);
      this._ewsVersions$.next(this.ewsVersions);
    }));
  }

  /***************************/
  // Priorities relevant stuff
  /***************************/
  public subscribeToPriorities(observer: PartialObserver<any>) {
    return this._priorities$.subscribe(observer);
  }

  public getPriorities() {
    return this._graphqlService.getPriorities()
      .pipe(tap((result) => {
        this.priorities = JSON.parse(JSON.stringify(result.data.priorities));
        this._priorities$.next(this.priorities);
      }));
  }

  /********************************/
  // Global Settings relevant stuff
  /********************************/

  isEwsCalEnabled$(): BehaviorSubject<boolean> {
    return this._ewsCalEnabled$;
  }

  isExchangeOnlineCalEnabled$(): BehaviorSubject<boolean> {
    return this._exchangeOnlineEnabled$;
  }

  isGoogleCalEnabled$(): BehaviorSubject<boolean> {
    return this._googleCalEnabled$;
  }

  isGoogleEnabled$(): BehaviorSubject<boolean> {
    return this._googleEnabled$;
  }

  isGoogleContactsEnabled$(): BehaviorSubject<boolean> {
    return this._googleContactsEnabled$;
  }

  isCaldavEnabled$(): BehaviorSubject<boolean> {
    return this._caldavEnabled$;
  }

  loadGlobalSettings(): Observable<any> {
    return this._graphqlService.getGlobalSettings().pipe(tap((result) => {
      const settings = JSON.parse(JSON.stringify(result.data.global_settings));
      settings.map((setting) => {
        this.globalSettings.set(setting.key, setting.value);
        this.emitGlobalSettingsChange(setting.key, setting.value);
      });
    }));
  }

  public setGlobalSettingsValue(key: string, value: string) {
    const currentValue = this.globalSettings.get(key);
    if (currentValue === undefined || currentValue !== value) {
      this.globalSettings.set(key, value);
      this._graphqlService.upsertGlobalSetting(key, value).subscribe();
    }
    this.emitGlobalSettingsChange(key, value);
  }

  emitGlobalSettingsChange(key: string, value: string) {
    if (key === GlobalSettingsKeys.GOOGLE_CALENDAR_ENABLED) {
      this._googleCalEnabled$.next(JSON.parse(value));
    }
    if (key === GlobalSettingsKeys.GOOGLE_CONTACTS_ENABLED) {
      this._googleContactsEnabled$.next(JSON.parse(value));
    }
    if (key === GlobalSettingsKeys.GOOGLE_ENABLED) {
      this._googleEnabled$.next(JSON.parse(value));
    }
    if (key === GlobalSettingsKeys.EWS_CALENDAR_ENABLED) {
      this._ewsCalEnabled$.next(JSON.parse(value));
    }
    if (key === GlobalSettingsKeys.EXCHANGE_ONLINE_CALENDAR_ENABLED) {
      this._exchangeOnlineEnabled$.next(JSON.parse(value));
    }
    if (key === GlobalSettingsKeys.CALDAV_ENABLED) {
      this._caldavEnabled$.next(JSON.parse(value));
    }
  }
}
