<form [formGroup]="form" (ngSubmit)="onSubmit()" class="c-dialog">
  <button class="c-dialog__close" (click)="dialogRef.close(false)">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <div class="c-dialog__body">
    <p class="c-dialog__title">{{'Template Attributes' | translate}}</p>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{'Template name' | translate}}</mat-label>
      <input type="text" [placeholder]="'Enter template name...' | translate" autocomplete="off" formControlName="templateName"
             matInput required>
      <mat-error>{{'Please, enter template name' | translate}}</mat-error>
    </mat-form-field>
    <mat-checkbox color="primary" formControlName="templateActive">{{'Show this template in selection list' | translate}}</mat-checkbox>
  </div>
  <div class="c-dialog__footer">
    <div class="c-dialog__footerBtns">
      <app-button btnStyle="outline" type="button" (click)="dialogRef.close(false)">{{'Cancel' | translate}}</app-button>
      <app-button type="submit">{{'Submit' | translate}}</app-button>
    </div>
  </div>
</form>
