import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class CancelGroupGQL extends Mutation {
  document = gql`
  mutation CANCEL_GROUP_GQL($id: BigInt!) {
    cancelGroup(id: $id) {
      errors
      changedTasks {
        id
        status
      }
    }
  }
  `;
}
