import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteTemplateGQL extends Mutation {
  document = gql`
    mutation deleteTemplate($id: BigInt!) {
      deleteTemplate(id: $id) {
        id
      }
    }`;
}
