import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class UpdateActivityStatusGQL extends Mutation {
  document = gql`
    mutation UPDATE_ACTIVITY_STATUS_GQL($id: bigint!, $status: String) {
      update_activities_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
        id
        description
      }
    }
  `;
}
