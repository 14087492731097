import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class UpdateTaskStatusGQL extends Mutation {
  document = gql`
  mutation UPDATE_TASK_STATUS_BY_PK_GQL($id: uuid!, $status: String) {
    update_tasks_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
      id
      description
    }
  }`;
}
