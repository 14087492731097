import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class CreateGroupContainerGQL extends Mutation {
  document = gql`
  mutation CreateGroupContainer(
    $background: String!,
    $width: Int!,
    $title: String!,
    $position: String!
  ) {
    createGroupContainer(
      group: {
        background: $background,
        position: $position,
        title: $title,
        width: $width
      }) {
      defaultTask {
        id
        description
        duration
        orderInGroup
        priorityId
      }
      group {
        id
        title
      }
    }
  }
  `;
}
