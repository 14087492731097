import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { RootComponent } from './components/root/root.component';
import { TopbarGlobalActionsComponent } from './components/topbar-global-actions/topbar-global-actions.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ObjectsModule } from './components/objects/objects.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [
    RootComponent,
    TopbarGlobalActionsComponent,
  ],
    imports: [
        CommonModule,
        ApolloModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        BrowserModule,
        RouterModule,
        MatSlideToggleModule,
        ObjectsModule,
        TranslateModule,
        MatMenuModule,
        MatRippleModule,
    ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: 'v1/graphql',
          }),
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class CoreModule {
}
