import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppSettingsService } from 'src/app/core/services/app-settings.service';
import { Calendar, GoogleCalendarService } from '../../services/google-calendar.service';
import { GoogleSigninService } from '../../services/google-signin.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { GlobalSettingsKeys } from '../../../../core/models/enums';

@UntilDestroy()
@Component({
  selector: 'app-google-calendar-settings',
  templateUrl: './google-calendar-settings.component.html',
  styleUrls: ['./google-calendar-settings.component.scss'],
})
export class GoogleCalendarSettingsComponent implements OnInit, OnDestroy {
  googleEnabled: boolean;
  googleCalendarEnabled: boolean;
  googleContactsEnabled: boolean;
  calendars: Calendar[] = [];
  calSelected: boolean[];
  calEnabled: Map<String, boolean> = new Map();
  calendarListSub = Subscription.EMPTY;
  readonly GlobalSettingsKeys = GlobalSettingsKeys;
  googleUserAuthenticated = false;

  constructor(
    private appSettingsService: AppSettingsService,
    private googleSigninService: GoogleSigninService,
    private googleCalendarService: GoogleCalendarService,
  ) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.appSettingsService.isGoogleEnabled$()
      .pipe(untilDestroyed(this))
      .subscribe(async (enabled) => {
        this.googleEnabled = enabled;
        if (enabled) {
          await this.googleSigninService.signIn().then((res) => {
            this.googleSigninService.checkIfUserAuthenticated().then((userAuthenticated) => {
              this.googleUserAuthenticated = userAuthenticated;
            });
          });
          if (!this.calendars.length) {
            this.getListCalendar(enabled);
          }
        } else {
          this.googleSigninService.signout();
          this.googleUserAuthenticated = false;
        }
      });

    if (this.googleEnabled) {
      this.appSettingsService.isGoogleCalEnabled$()
        .pipe(untilDestroyed(this))
        .subscribe((enabled) => {
          this.googleCalendarEnabled = enabled;
          this.getListCalendar(enabled);
        });

      this.appSettingsService.isGoogleContactsEnabled$()
        .pipe(untilDestroyed(this))
        .subscribe((enabled) => {
          this.googleContactsEnabled = enabled;
        });
    }
  }

  private getListCalendar(calendarsEnabled) {
    if (calendarsEnabled) {
      this.listCalendars();
    } else {
      this.calendars = [];
      this.calSelected = [];
    }
  }

  selectGoogleFeature(enabled: boolean, globalSettingsKeys: GlobalSettingsKeys) {
    this.appSettingsService.setGlobalSettingsValue(globalSettingsKeys, enabled.toString());
  }

  selectCalendar(id: string) {
    this.googleCalendarService.selectCalendar(id)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        this.calendars.find((el) => el.id === id).configured = true;
      });
  }

  listCalendars() {
    this.googleCalendarService.prepareAllGoogleCalendars().then((googleCalendars) => {
      this.calendarListSub = this.googleCalendarService.getGoogleCalendarList()
        .pipe(take(1), untilDestroyed(this))
        .subscribe((calendars) => {
          this.calendars = calendars;
        });
    });
  }
}
