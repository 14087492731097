import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class CloneActivityGQL extends Mutation {
  document = gql`
    mutation cloneActivity($id: BigInt!){
      cloneActivity (id: $id)
      {
          activity {
            id
            note
            date
            phaseDefault
            nextActivityId
            previousActivityId
            duration
            done
            description
            fixedStart
            templateId
            phase {
              id
            }
            priority {
              id
              description
            }
          }
          changedActivities {
            i
            p
            n
            ph
          }
          errors
      }
    }
  `;
}
