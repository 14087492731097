import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { NgProgressService } from '../../../shared/services/ng-progress.service';
import { DayEditorOnboardingIdEnum } from '../objects/onboarding-tooltips/onboarding-tooltips.helper';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit {
  readonly DayEditorOnboardingIdEnum = DayEditorOnboardingIdEnum;

  constructor(
    public authService: AuthService,
    private progressService: NgProgressService,
  ) {
  }

  ngOnInit(): void {
  }

  get dropdownName() {
    let nameResult = '';

    this.authService.getUser.display_name.split(' ').forEach((word) => {
      if (!word || nameResult.length === 3) {
        return;
      }
      nameResult += word[0];
    });

    return nameResult;
  }

  logout() {
    this.progressService.start();
    this.authService.logout()
      .pipe(
        take(1),
        finalize(() => this.progressService.complete()),
        untilDestroyed(this),
      ).subscribe();
  }
}
