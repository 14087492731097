import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'static-notification',
  templateUrl: './static-notification.component.html',
  styleUrls: ['./static-notification.component.scss'],
})
export class StaticNotificationComponent implements OnInit {
  @Input() title: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
