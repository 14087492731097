import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DayEditorComponent } from './components/day-editor/day-editor.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivityListComponent } from './components/activity-list/activity-list.component';
import { ActivityListEntryComponent } from './components/activity-list-entry/activity-list-entry.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddPhaseDialog } from './components/day-editor/add-phase-dialog';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { PhaseListComponent } from './components/phase-list/phase-list.component';
import { EditTemplateDialog } from './components/template-editor/edit-template-dialog';
import { SelectTemplateDialog } from './components/day-editor/select-template-dialog';
import { AppointmentListComponent } from './components/appointment-list/appointment-list.component';
import { AppointmentListEntryComponent } from './components/appointment-list-entry/appointment-list-entry.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { ObjectsModule } from '../../core/components/objects/objects.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { OnboardingDialogComponent } from './components/dialogs/onboarding-dialog/onboarding-dialog.component';
import { TimeProgressComponent } from './components/phase-list/time-progress/time-progress.component';
import { PlanningBoardClipboardModule } from './components/planning-board/planning-board-clipboard/planning-board-clipboard.module';
import { ActivityEntryManagementComponent } from './components/activity-entry-management/activity-entry-management.component';
import { QuillModule } from 'ngx-quill';
import { ActivityEntryInfoOverlayComponent } from './components/activity-list-entry/activity-entry-info-overlay/activity-entry-info-overlay.component';

@NgModule({
  declarations: [
    DayEditorComponent,
    ActivityListComponent,
    ActivityListEntryComponent,
    AddPhaseDialog,
    EditTemplateDialog,
    SelectTemplateDialog,
    TemplateEditorComponent,
    PhaseListComponent,
    AppointmentListComponent,
    AppointmentListEntryComponent,
    OnboardingDialogComponent,
    TimeProgressComponent,
    ActivityEntryManagementComponent,
    ActivityEntryInfoOverlayComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    SharedModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatSidenavModule,
    MatDialogModule,
    MatRippleModule,
    ObjectsModule,
    MatMenuModule,
    MatExpansionModule,
    MatListModule,
    MatTooltipModule,
    TranslateModule,
    QuillModule.forRoot(),
    PlanningBoardClipboardModule,
  ],
  providers: [
    MatDatepickerModule,
  ],
  exports: [
  ],
})
export class DayPlannerModule {
}
