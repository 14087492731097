import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class UpdateTemplateGQL extends Mutation {
  document = gql`
    mutation update_templates_by_pk($id: bigint!, $name: String, $active: Boolean) {
      update_templates_by_pk(pk_columns: {id: $id}, _set: {active: $active, name: $name}) {
        id
        default
        active
      }
    }`;
}
