import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class FetchAllGroupContainersGQL extends Query<any> {
  document = gql`
     query FetchAllGroupContainersGQL {
      groups {
        id
        title
        position
        created_at
        background
        updated_at
        width
        status
        tasks(order_by: {order_in_group: asc}) {
          id
          duration
          is_expanded
          is_implicit
          is_resolved
          is_default
          description
          status
          type
          clipboard
          priority {
            id
            description
          }
          group {
            id
          }
          order_in_group
          activities(order_by: {order_in_task: asc}) {
            created_at
            id
            date
            in_clipboard
            description
            duration
            done
            order_in_clipboard
            order_in_phase
            order_in_task
            status
            phase {
              id
            }
            task {
              id
              status
              is_implicit
              group {
                id
              }
            }
            priority {
              id
              description
            }
          }
        }
      }
    }
  `;
}
