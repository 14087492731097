import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { EwsConfig } from 'src/app/core/models/interfaces';
import { AppSettingsService } from 'src/app/core/services/app-settings.service';
import { GraphqlService } from 'src/app/core/services/graphql.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GlobalSettingsKeys } from '../../../../core/models/enums';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@UntilDestroy()
@Component({
  selector: 'app-ews-settings',
  templateUrl: './ews-settings.component.html',
  styleUrls: ['./ews-settings.component.scss'],
})
export class EwsSettingsComponent implements OnInit, OnDestroy {
  ewsFeatureSelected: boolean = false;
  ewsConfigForm: FormGroup = this.formBuilder.group({
    ews_url: [null, Validators.required],
    ews_user: [null, Validators.required],
    ews_exchange_version: [null, Validators.required],
    ews_password: [null, Validators.required],
  });
  ewsConfig: EwsConfig;
  ewsVersions: string[];
  ewsConfigValid: boolean = false;

  constructor(
    private appSettingsService: AppSettingsService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private _notificationService: NotificationService,
    private _graphqlService: GraphqlService,
  ) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.appSettingsService.isEwsCalEnabled$()
      .pipe(
        tap((enabled) => this.ewsFeatureSelected = enabled),
        filter((enabled) => !!enabled),
        switchMap(() => this.appSettingsService.ewsConfig$()),
        untilDestroyed(this),
      ).subscribe((ewsConfig) => {
      this.ewsConfig = ewsConfig;
      if (this.ewsConfig === undefined) {
        this.ewsConfig = {
          id: undefined,
          ews_url: '',
          ews_user: '',
          ews_password: '',
          ews_exchange_version: '',
        };
      }
      this.ewsConfigForm.patchValue({
        ews_url: this.ewsConfig.ews_url,
        ews_user: this.ewsConfig.ews_user,
        ews_exchange_version: this.ewsConfig.ews_exchange_version,
        ews_password: this.ewsConfig.ews_password,
      });
    });

    this.ewsConfigValid = false;

    this.appSettingsService.subscribeToEwsVersions({
      next: (ewsVersions) => {
        this.ewsVersions = ewsVersions;
      },
    });
  }

  selectEwsFeature(enabled: EventEmitter<any>) {
    this.appSettingsService.setGlobalSettingsValue(GlobalSettingsKeys.EWS_CALENDAR_ENABLED, enabled.toString());
  }

  checkEwsConfig() {
    if (this.ewsConfigForm.valid) {
      this._graphqlService.checkEwsConfig(
        this.ewsConfigForm.value.ews_url,
        this.ewsConfigForm.value.ews_user,
        this.ewsConfigForm.value.ews_password,
        this.ewsConfigForm.value.ews_exchange_version,
      ).pipe(take(1), untilDestroyed(this)).subscribe(
        (result) => {
          result = JSON.parse(JSON.stringify(result));
          if (result.errors !== undefined) {
            this._notificationService.error({
              title: this.translate.instant(marker('EWS error')),
              description: this.translate.instant(marker('EWS configuration not valid!')),
            });
            this.ewsConfigValid = false;
          } else {
            this._notificationService.success({
              title: this.translate.instant(marker('Ohh yeah')),
              description: this.translate.instant(marker('EWS configuration valid')),
            });
            this.ewsConfigValid = true;
          }
        },
      );
    }
  }

  onEwsConfigSubmit() {
    if (this.ewsConfigForm.valid) {
      this.appSettingsService.setEwsConfig(
        {
          id: this.ewsConfig.id,
          ews_url: this.ewsConfigForm.value.ews_url,
          ews_user: this.ewsConfigForm.value.ews_user,
          ews_password: this.ewsConfigForm.value.ews_password,
          ews_exchange_version: this.ewsConfigForm.value.ews_exchange_version,
        },
      );
    }
  }

}
