<div class="c-settingsIntegrations__content">
  <div class="c-settingsIntegrations__header">
    <p class="c-settingsIntegrations__title">
      {{'Google Integrations' | translate}}
    </p>
    <mat-slide-toggle
      color="primary"
      class="c-settingsIntegrations__toggle"
      [(ngModel)]="googleEnabled"
      (ngModelChange)="selectGoogleFeature($event, GlobalSettingsKeys.GOOGLE_ENABLED)"></mat-slide-toggle>
  </div>

  <ng-container *ngIf="googleEnabled && googleUserAuthenticated">
    <div class="c-settingsIntegrations__header">
      <p class="c-settingsIntegrations__title">
        {{'Google Calendar' | translate}}
      </p>
      <mat-slide-toggle
        color="primary"
        class="c-settingsIntegrations__toggle"
        [(ngModel)]="googleCalendarEnabled"
        (ngModelChange)="selectGoogleFeature($event, GlobalSettingsKeys.GOOGLE_CALENDAR_ENABLED)"></mat-slide-toggle>
    </div>
    <div class="c-settingsIntegrations__body">
      <ng-container *ngIf="calendarListSub.closed else settingsLoading">
        <ul *ngIf="googleCalendarEnabled" class="c-settingsIntegrations__list">
          <li class="c-settingsIntegrations__listItem"  *ngFor="let cal of calendars; let i=index">
            <div>
              <div *ngIf="cal.summaryOverride">
                <div class="calendar-entry"> {{cal.summaryOverride}}</div>
              </div>
              <div *ngIf="!cal.summaryOverride">
                <div class="calendar-entry"> {{cal.summary}}</div>
              </div>
            </div>
            <mat-checkbox color="primary" [(ngModel)]="cal.enabled" (ngModelChange)="selectCalendar(cal.id)"></mat-checkbox>
          </li>
        </ul>
      </ng-container>
      <ng-template #settingsLoading>
        <spinner class="c-settings__spinner"></spinner>
      </ng-template>
    </div>

    <div class="c-settingsIntegrations__header">
      <p class="c-settingsIntegrations__title">
        {{'Google Contacts' | translate}}
      </p>
      <mat-slide-toggle
        color="primary"
        class="c-settingsIntegrations__toggle"
        [(ngModel)]="googleContactsEnabled"
        (ngModelChange)="selectGoogleFeature($event, GlobalSettingsKeys.GOOGLE_CONTACTS_ENABLED)"></mat-slide-toggle>
    </div>
  </ng-container>
</div>
