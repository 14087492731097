import { Activity, Priority } from '../../../../core/models/interfaces';

export const DEFAULT_CONTAINER_WIDTH = 500;
export const sortStep = 1000;
export const ZOOM_DATA_STORAGE_KEY = 'planningBoard-zoom-data';

export type UUID = string;
export type PriorityID = number;
export type GroupId = number;

export enum PlanningBoardClipboardParent {
  DayEditor = 'dayEditor',
  PlanningBoard = 'planningBoard',
}

export interface GroupContainerBackgroundInterface {
  id: number;
  color: string;
}

export const GroupContainerBackgrounds: GroupContainerBackgroundInterface[] = [
  {
    id: 0,
    color: '#F44336',
  },
  {
    id: 1,
    color: '#E91E63',
  },
  {
    id: 2,
    color: '#FF5722',
  },
  {
    id: 3,
    color: '#FF9800',
  },
  {
    id: 4,
    color: '#FFC107',
  },
  {
    id: 5,
    color: '#8BC34A',
  },
  {
    id: 6,
    color: '#4CAF50',
  },
  {
    id: 7,
    color: '#00BCD4',
  },
  {
    id: 8,
    color: '#009688',
  },
  {
    id: 9,
    color: '#03A9F4',
  },
  {
    id: 10,
    color: '#3F51B5',
  },
  {
    id: 11,
    color: '#673AB7',
  },
  {
    id: 12,
    color: '#9C27B0',
  },
  {
    id: 13,
    color: '#607D8B',
  },
  {
    id: 14,
    color: '#795548',
  },
];

export type PlanningBoardActivityParent = 'container' | 'clipboard';

export interface AssignedGroupContainer {
  id: number;
  title: string;
  expanded: boolean;
  background: string;
  tasks: AssignedGroupContainerTask[];
}

export interface AssignedGroupContainerTask {
  id: string;
  is_implicit: boolean;
  description: string;
}

export interface PlanningBoardContainer {
  id: number;
  position: string;
  title: string;
  tasks: PlanningBoardTask[];
  background: string;
  width: number;
  status?: GroupContainerStatusKey;
}

export interface PlanningBoardTask {
  id: string;
  is_expanded: boolean;
  is_resolved: boolean;
  is_implicit: boolean;
  is_default: boolean;
  description: string;
  duration: number;
  style: any;
  priority: Priority;
  clipboard: boolean;
  group: {
    id: GroupId;
    title: string;
  };
  order_in_group?: number;
  status?: TaskStatusKey;
  activities: Activity[];
}

export interface ChangeTaskStatusActivity {
  id: number;
  done: boolean,
  status?: ActivityStatus;
}

export type GroupContainerStatusKey = 'ACTIVE' | 'DONE' | 'CANCELED'
export type TaskStatusKey = 'TODO' | 'IN PROGRESS' | 'DONE' | 'CANCELED'
export type ActivityStatusKey = 'TODO' | 'DONE'

export type ActivityStatus = Status<ActivityStatusKey>
export type GroupContainerStatus = Status<GroupContainerStatusKey>
export type TaskStatus = Status<TaskStatusKey>

type Status<TaskStatusKey> = {
  is_new: boolean,
  is_resolved: boolean,
  key: TaskStatusKey,
};

export interface StorageZoomLevel {
  zoomLevel: number;
  x: number;
  y: number;
}

export function generateArithmeticMeanSorting(currentIndex, items: any, sortKey: string) {
  const previousTaskBeforeToTask = items[currentIndex - 1];
  const nextTaskAfterToTask = items[currentIndex + 1];

  let arithmeticMeanSorting = sortStep;

  if (currentIndex !== 0 && currentIndex !== items.length - 1) {
    arithmeticMeanSorting = (nextTaskAfterToTask[sortKey] + previousTaskBeforeToTask[sortKey]) / 2;
  } else {
    if (items.length > 1) {
      if (currentIndex === 0) {
        arithmeticMeanSorting = nextTaskAfterToTask[sortKey] / 2;
      }

      if (currentIndex === items.length - 1) {
        arithmeticMeanSorting = previousTaskBeforeToTask[sortKey] + sortStep;
      }
    }
  }
  return +arithmeticMeanSorting.toFixed();
}
