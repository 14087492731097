import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Activity, Phase, Priority, Template } from '../../../../core/models/interfaces';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { DateTime } from 'luxon';
import { MatDialog } from '@angular/material/dialog';
import { AddPhaseDialog } from './add-phase-dialog';
import { SelectTemplateDialog } from './select-template-dialog';
import { filter, switchMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationService } from '../../../../core/services/notification.service';
import { FormControl } from '@angular/forms';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { CookieService } from 'ngx-cookie';
import { UserLocaleService } from 'src/app/shared/services/user-locale.service';
import {
  DayEditorOnboardingIdEnum,
  getDayEditorOnboardingTooltips,
} from '../../../../core/components/objects/onboarding-tooltips/onboarding-tooltips.helper';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { OnboardingDialogComponent } from '../dialogs/onboarding-dialog/onboarding-dialog.component';
import { BehaviorSubject } from 'rxjs';
import { ActivityStatus, PlanningBoardClipboardParent } from '../planning-board/planning-board.helper';
import { AppSettingsService } from '../../../../core/services/app-settings.service';
import { ActivityManagementService } from 'src/app/core/services/activity-management.service';
import {
  PlanningBoardClipboardService,
} from '../planning-board/planning-board-clipboard/planning-board-clipboard.service';
import { ActivitiesService } from '../planning-board/activities.service';

const SEVEN_DAY_IN_MILLISECONDS = 604800000;
const LAST_DAY_IN_WEEK = 7;
const week = [
  {
    name: marker('Mon'),
    date: null,
  },
  {
    name: marker('Tue'),
    date: null,
  },
  {
    name: marker('Wed'),
    date: null,
  },
  {
    name: marker('Thu'),
    date: null,
  },
  {
    name: marker('Fri'),
    date: null,
  },
  {
    name: marker('Sat'),
    date: null,
  },
  {
    name: marker('Sun'),
    date: null,
  },
];

@UntilDestroy()
@Component({
  selector: 'app-day-editor',
  templateUrl: './day-editor.component.html',
  styleUrls: ['./day-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DayEditorComponent implements OnInit, AfterViewInit {
  @ViewChild('rightTabGroup') rightTabGroup: MatTabGroup;
  readonly DayEditorOnboardingIdEnum = DayEditorOnboardingIdEnum;
  readonly PlanningBoardClipboardParent = PlanningBoardClipboardParent;
  phases: Phase[] = [];
  activities: Activity[] = [];
  templates: Template[] = [];
  priorities: Priority[] = [];
  date: string = DateTime.now().toFormat('yyyy-MM-dd');
  selectedDate: Date = new Date();
  selectedDateControl: FormControl = new FormControl(this.date);
  navigationWeekMonth = new Date();
  currentDay = this.cookie.hasKey('selectedDate')
    ? new Date(JSON.parse(this.cookie.get('selectedDate')) || 'null')
    : new Date();
  loading = true;
  error: string = '';
  week = week;
  today = new Date();
  dayEditorOnboardingTooltips = null;
  priorityPainterEnable = false;
  changePriorities$ = new BehaviorSubject(false);
  activityMoreInfoData: Activity = null;
  priorityPainterIndex = 0;
  activityStatuses: ActivityStatus[] = [];

  constructor(
    private dataProviderService: DataproviderService,
    private dialog: MatDialog,
    private cookie: CookieService,
    private translate: TranslateService,
    private _notificationService: NotificationService,
    private appSettingsService: AppSettingsService,
    private activityManagementService: ActivityManagementService,
    private planningBoardClipboardService: PlanningBoardClipboardService,
    private activitiesService: ActivitiesService,
    private cd: ChangeDetectorRef,
    public userLocaleService: UserLocaleService,
  ) {
    this.fetchActivitiesStatuses();
    this.planningBoardClipboardService.getAllClipboardActivities()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((res) => {
        this.planningBoardClipboardService.setClipboardActivities(res.data.activities);
      });
  }

  ngOnInit(): void {
    this.setDate(this.currentDay);
    this.setTodayIfNewDayHasCome();

    this.appSettingsService.subscribeToPriorities({
      next: (priorities) => {
        this.priorities = priorities;
      },
    });

    this.dataProviderService.subscribeToDays({
      next: () => {
        this.refreshData();
      },
    });
    this.dataProviderService.subscribeToTemplates({
      next: (templates) => this.templates = templates,
    });

    this.showFirstOnboardingDialog();
  }

  ngAfterViewInit() {
    this.getMoreInfoForActivity();
  }

  fetchActivitiesStatuses() {
    this.activitiesService.fetchStatuses$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((statuses) => {
        this.activityStatuses = statuses;
      });
  }

  getMoreInfoForActivity() {
    this.activityManagementService.activityData$
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.activityMoreInfoData = data;
        this.cd.detectChanges();
      });
  }

  setPriorityPainterData($event: { enable: boolean; priorityPainterIndex: number }) {
    this.priorityPainterEnable = $event.enable;
    this.priorityPainterIndex = $event.priorityPainterIndex;
  }

  setTodayIfNewDayHasCome() {
    setInterval(() => {
      if (this.today.getDate() === new Date().getDate() - 1) {
        this.setDate(new Date());
        window.location.reload();
      }
    }, 300000);
  }

  private showFirstOnboardingDialog() {
    const dayEditorOnboardingTooltips = getDayEditorOnboardingTooltips(this.phases.length > 0);
    if (!this.cookie.hasKey('first-onboarding-dialog')) {
      if (this.cookie.hasKey(Object.keys(dayEditorOnboardingTooltips)[0])) {
        this.cookie.remove(Object.keys(dayEditorOnboardingTooltips)[0]);
      }
      this.dialog.open(OnboardingDialogComponent, {disableClose: true}).afterClosed()
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this.dayEditorOnboardingTooltips = dayEditorOnboardingTooltips;
        });
    } else {
      this.dayEditorOnboardingTooltips = dayEditorOnboardingTooltips;
    }
  }

  isSelectedDay(date: Date): boolean {
    return date.toDateString() === this.selectedDate.toDateString();
  }

  isToday(date: Date): boolean {
    return date.toDateString() === new Date().toDateString();
  }

  getWeekDays(date: Date) {
    const day = new Date(date);
    for (let i = 1; i <= this.week.length; i++) {
      let first = day.getDate() - (day.getDay() === 0 ? LAST_DAY_IN_WEEK : day.getDay()) + i;
      this.week[i - 1].date = new Date(day.setDate(first));
      this.navigationWeekMonth = this.week[0].date;
    }
  }

  prevWeek() {
    this.currentDay = new Date(this.currentDay.getTime() - SEVEN_DAY_IN_MILLISECONDS);
    this.getWeekDays(this.currentDay);
  }

  nextWeek() {
    this.currentDay = new Date(this.currentDay.getTime() + SEVEN_DAY_IN_MILLISECONDS);
    this.getWeekDays(this.currentDay);
  }

  refreshData() {
    this.phases = this.dataProviderService.getDayPhases(this.date);
  }

  setDate(event: Date) {
    if (event) {
      this.selectedDate = event;
      this.selectedDateControl.patchValue(event);
      this.date = DateTime.fromJSDate(event).toFormat('yyyy-MM-dd');
      this.dataProviderService.changeDate(this.date);
      this.currentDay = event;
      this.getWeekDays(event);
      this.cookie.put('selectedDate', JSON.stringify(this.currentDay));
    }
  }

  deleteDay(): void {
    this.dataProviderService.deleteDay(this.date)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        this._notificationService.success({
          title: this.translate.instant(marker('Very well...')),
          description: this.translate.instant(marker('Successfully deleted day')),
        });
      }, (error) => {
        this._notificationService.error({
          title: this.translate.instant(marker('Well, failure...')),
          description: this.translate.instant(marker('Failed to delete day')),
        });
      });
  }

  addFromTemplate() {
    const dialogRef = this.dialog.open(SelectTemplateDialog, {
      data: {templates: this.templates.filter((tpl) => !!tpl.active)},
    });
    dialogRef.afterClosed().pipe(
      take(1),
      filter((template) => !!template),
      switchMap((template: Template) => this.dataProviderService.insertNewDayFromTemplate(template.id, this.date)),
      untilDestroyed(this),
    ).subscribe();
  }

  openPhaseAddDialog() {
    this.dialog.open(AddPhaseDialog, {
      data: {
        date: this.date,
        templateId: -1,
      },
    });
  }

  get priorityIdByPainterIndex(): number {
    return this.priorities[this.priorityPainterIndex].id;
  }

  setPriorityPainterIndex(priority: Priority) {
    this.priorityPainterIndex = this.priorities.findIndex((el) => el.id === priority.id);
  }
}


