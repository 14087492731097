import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExchangeOnlineComponent } from './components/exchange-online/exchange-online.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ObjectsModule } from '../../core/components/objects/objects.module';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    ExchangeOnlineComponent,
  ],
    imports: [
        CommonModule,
        MatSlideToggleModule,
        FormsModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: '172cbb8a-39db-4be5-8507-55f949023ba8',
                authority: 'https://login.microsoftonline.com/common/',
                redirectUri: environment.siteUrl,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            },
        }), {
            interactionType: InteractionType.Popup,
            authRequest: {
                scopes: ['user.read', 'calendars.read', 'calendars.read.shared'],
            },
        }, {
            interactionType: InteractionType.Popup,
            protectedResourceMap: new Map([
                ['https://graph.microsoft.com/v1.0/me', ['user.read', 'calendars.read', 'calendars.read.shared']],
            ]),
        }),
        MatCheckboxModule,
        ObjectsModule,
        TranslateModule,
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
  ],
  exports: [
    ExchangeOnlineComponent,
  ],
})
export class MicrosoftModule { }
