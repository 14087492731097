import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class UpsertGlobalSettingGQL extends Mutation {
  document = gql`
    mutation upsert_global_settings($value: String = "", $key: String = "") {
      insert_global_settings(objects: {key: $key, value: $value},
        on_conflict: {constraint: global_settings_pkey, update_columns: value}) {
        returning {
          key
          user_id
          value
        }
      }
    }
    `;
}
