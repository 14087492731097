import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class UpdateCalendarConfigGQL extends Mutation {
  document = gql`
    mutation update_calendar_selection_config($enabled: Boolean = true, $calendar_id: String = "") {
      update_calendar_selection_config(where: {calendar_id: {_eq: $calendar_id}}, _set: {enabled: $enabled}) {
        affected_rows
      }
    }`;
}
