import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { CookieService } from 'ngx-cookie';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from './modules/auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'BeMyDay';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cookie: CookieService,
    private auth: AuthService,
    private themeService: ThemeService,
  ) {
  }

  ngOnInit(): void {
    this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/sprites/global.svg`));
    this.themeService.initTheme(this.cookie.get('dark'));
  }
}
