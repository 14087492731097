import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  ActivityStatus, GroupContainerStatus, GroupContainerStatusKey,
  TaskStatus,
} from '../../../../modules/day-planner/components/planning-board/planning-board.helper';

@Component({
  selector: 'entity-status',
  templateUrl: './entity-status.component.html',
  styleUrls: ['./entity-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EntityStatusComponent {
  @Input() activeStatus: ActivityStatus | TaskStatus | GroupContainerStatus;
  @Input() statuses!: ActivityStatus[] | TaskStatus[] | GroupContainerStatus[];

  @Output() updateStatus = new EventEmitter();

  constructor() {
  }
}
