import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class InsertEwsConfigGQL extends Mutation {
  document = gql`
    mutation insert_ews_config_one($ews_url: String, $ews_user: String, $ews_password: String, $ews_exchange_version: String) {
      insert_ews_config_one(object: {ews_user: $ews_user, ews_url: $ews_url, ews_password: $ews_password, ews_exchange_version: $ews_exchange_version})
      {
        id
      }
    }`;
}
