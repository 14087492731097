import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class MoveTaskToAnotherContainerGQL extends Mutation {
  document = gql`
  mutation MoveTaskToAnotherContainerGQL(
    $id: uuid!,
    $group_id: bigint!,
    $order_in_group: Int!,
  ) {
    update_tasks_by_pk(
      pk_columns: {id: $id},
      _set: {
        group_id: $group_id,
        order_in_group: $order_in_group
      }) {
      id
    }
  }
  `;
}
