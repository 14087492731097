<div class="c-root">
  <div class="c-rootSidebar">
    <div class="c-rootSidebar__body">
      <a routerLink="/app/day-editor">
        <p class="c-rootSidebar__logo">PMD</p>
      </a>
    </div>

    <ul class="c-rootTabs">
      <li class="c-rootTabs__item" [id]="DayEditorOnboardingIdEnum.First">
        <a routerLink="/app/day-editor" routerLinkActive="active" class="c-rootTabs__link">
          <mat-icon class="c-rootTabs__icon">format_list_bulleted</mat-icon> <span class="c-rootTabs__linkText">{{'My day' | translate}}</span>
        </a>
      </li>
      <li class="c-rootTabs__item" [id]="DayEditorOnboardingIdEnum.Seventh">
        <a routerLink="/app/template-editor" routerLinkActive="active" class="c-rootTabs__link">
          <mat-icon class="c-rootTabs__icon" svgIcon="template"></mat-icon> <span class="c-rootTabs__linkText">{{'Template' | translate}}</span>
        </a>
      </li>
      <li class="c-rootTabs__item">
        <a routerLink="/app/planning-board" routerLinkActive="active" class="c-rootTabs__link">
          <mat-icon class="c-rootTabs__icon">dashboard</mat-icon> <span class="c-rootTabs__linkText">{{'Planning board' | translate}}</span>
        </a>
      </li>
      <li class="c-rootTabs__item">
        <a routerLink="/app/settings" routerLinkActive="active" class="c-rootTabs__link">
          <mat-icon class="c-rootTabs__icon" svgIcon="settings"></mat-icon> <span class="c-rootTabs__linkText">{{'Settings' | translate}}</span>
        </a>
      </li>
    </ul>

    <button class="c-rootSidebarDropdown">
      <span [matMenuTriggerFor]="dropdown" class="c-rootSidebarDropdown__logo" matRipple>{{dropdownName}}</span>
    </button>
    <mat-menu #dropdown="matMenu" class="c-rootSidebarDropdown__menu" yPosition="above" xPosition="after">
      <div class="c-rootSidebarDropdown__header">
        <p class="c-rootSidebarDropdown__name">{{authService.getUser.display_name}}</p>
        <p class="c-rootSidebarDropdown__email">{{authService.getUser.email}}</p>
      </div>
      <button routerLink="/app/settings/general" mat-menu-item>
        {{'Profile settings' | translate}} <theme-switcher></theme-switcher>
      </button>
      <button (click)="$event.stopPropagation()" mat-menu-item>
        <language-selection>{{'Language' | translate}}</language-selection>
      </button>
      <button (click)="$event.stopPropagation()" mat-menu-item>
        <theme-switcher>{{'Dark mode' | translate}}</theme-switcher>
      </button>
      <button mat-menu-item class="logout" (click)="logout()">
        <mat-icon svgIcon="logout" class="c-rootSidebar__logoutIcon"></mat-icon> {{'Logout' | translate}}
      </button>
    </mat-menu>
  </div>
  <div class="c-rootContent">
    <div class="c-rootBody">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
