import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class CreateNewTaskGQL extends Mutation {
  document = gql`
  mutation CreateNewTaskGQL(
    $group_id: bigint!,
    $description: String,
    $duration: Int!,
    $priority_id: bigint!
    $order_in_group: Int!
    $status: String,
    $is_implicit: Boolean,
    $is_expanded: Boolean
  ) {
    insert_tasks_one(
      object: {
        group_id: $group_id,
        description: $description,
        duration: $duration,
        priority_id: $priority_id,
        order_in_group: $order_in_group,
        status: $status,
        is_implicit: $is_implicit,
        is_expanded: $is_expanded
      }
    ) {
      id
    }
  }
  `;
}
