<ng-container *ngIf="!appointment.planned; else appointmentIsPlanned">
  <button (click)="toActivityList()" [disabled]="appointment.allDay" [matTooltip]="'Add to day list' | translate" mat-icon-button>
    <mat-icon *ngIf="!appointment.allDay">chevron_left</mat-icon>
  </button>
</ng-container>
<ng-template #appointmentIsPlanned>
  <button class="text-gray" disabled mat-icon-button>
    <mat-icon>check</mat-icon>
  </button>
</ng-template>
<div class="c-appointmentsList__itemFields">
  <p class="c-appointmentsList__itemTitle" [matTooltip]="appointment.summary">
    {{appointment.summary}}
  </p>
  <div class="c-appointmentsList__itemTime">
    <ng-container *ngIf="!appointment.allDay">
      {{appointment.startTime | dateTimeFormat}} - {{appointment.endTime | dateTimeFormat}}
    </ng-container>
    <ng-container *ngIf="appointment.allDay">
      {{appointment.startTime | dateTimeFormat:userLocaleService.format.date}} - {{appointment.endTime | dateTimeFormat:userLocaleService.format.date}}
    </ng-container>
  </div>
</div>
<a [href]="appointment.extLink" target="_blank" rel="noopener noreferrer" [matTooltip]="'Open link' | translate" class="c-appointmentsList__itemLink" mat-icon-button>
  <mat-icon svgIcon="calendar-{{appointment.provider}}" class="c-appointmentsList__itemLinkIcon"></mat-icon>
</a>
