import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../../services/theme.service';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss'],
})
export class ThemeSwitcherComponent implements OnInit {
  darkModeEnabled$ = this.themeService.darkModeEnabled$;

  constructor(
    private themeService: ThemeService,
    private cookie: CookieService,
  ) { }

  ngOnInit(): void {
  }


  switchTheme(): void {
    const theme = this.cookie.get('dark') !== 'true';
    this.themeService.toggleDarkTheme(theme);
  }
}
