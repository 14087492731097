import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({providedIn: 'root'})
export class ConfirmationService {

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    ) {}

  openDialog(message?: string) : Observable<any> {
    let msg = this.translate.instant(marker('Are you sure want to delete?'));
    if (message !== undefined) {
      msg = this.translate.instant(message);
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
      data:{
        message: msg,
        buttonText: {
          ok: this.translate.instant(marker('Yes')),
          cancel: this.translate.instant(marker('No')),
        },
      },
    });
    return dialogRef.afterClosed();
  }
}
