import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataproviderService } from 'src/app/core/services/dataprovider.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserLocaleService } from '../../../../shared/services/user-locale.service';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker/src/app/material-timepicker/models/ngx-material-timepicker-theme.interface';
import { ngxMaterialTimepickerTheme } from '../../../../_helpers/helpers';

@UntilDestroy()
@Component({
  selector: 'add-phase-dialog',
  templateUrl: './add-phase-dialog.html',
})
export class AddPhaseDialog implements OnInit {
  form: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required]],
    fixedStart: ['', Validators.required],
  });
  timepickerTheme: NgxMaterialTimepickerTheme = ngxMaterialTimepickerTheme;

  constructor(
    private formBuilder: FormBuilder,
    private userLocaleService: UserLocaleService,
    private dataProviderService: DataproviderService,
    @Inject(MAT_DIALOG_DATA) public data: { date: string, templateId: number },
    public dialogRef: MatDialogRef<AddPhaseDialog>,
  ) {
  }

  ngOnInit(): void {
  }

  get timeFormat() {
    return this.userLocaleService.format.time.includes('a') ? '12' : '24';
  }

  getFormControlsError(name: string, error: string) {
    return this.form.get(name).hasError(error);
  }

  onSubmit() {
    console.log(this.form.value);

    if (this.form.invalid) return;

    this.dataProviderService.createPhase(
      this.data.date,
      this.form.value.name,
      this.form.value.fixedStart,
      this.data.templateId)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  cancel() {
    this.dialogRef.close();
  }
}
