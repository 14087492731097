import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { ExchangeOnlineCalendarService } from '../../microsoft/services/exchange-online-calendar.service';
import { of } from 'rxjs';
import { NotificationService } from '../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const UNAUTHORIZED_HTTP_CODE = 401;

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private exchangeOnlineCalendarService: ExchangeOnlineCalendarService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = request;
    const token = `Bearer ${
      authReq.url.includes('microsoft') && this.exchangeOnlineCalendarService.exchangeOnlineJwtData
        ? this.exchangeOnlineCalendarService.exchangeOnlineJwtData.accessToken
        : this.authService.getToken().jwt_token
    }`;
    if (token) {
      authReq = request.clone({headers: request.headers.set('Authorization', token)});
    }
    return next.handle(authReq).pipe(
      switchMap((response: any) => {
        return response?.body?.errors?.length && response.body.errors[0].message.includes('JWTExpired')
          ? this.handle401Error()
          : of(response);
      }),
      catchError((error) => {
          return !authReq.url.includes('/login') && error.status === UNAUTHORIZED_HTTP_CODE
            ? this.handle401Error()
            : throwError(error);
        },
      ),
    );
  }

  private handle401Error() {
    return this.authService.refreshToken().pipe(
      tap(() => {
        this.notificationService.error({
          description: this.translate.instant(marker('Your login session has expired. Please repeat your actions or reload page.')),
          btnCaption: this.translate.instant(marker('Reload page')),
          func: () => window.location.reload(),
        }, {autoClose: false});
      }),
    );
  }
}
