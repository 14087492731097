<div class="c-dayEditor" cdkDropListGroup>
  <div class="c-dayEditor__header">
    <div class="c-dayEditorCalendar">
      <div class="c-dayEditorCalendar__month">
        <div class="c-dayEditorCalendar__monthTitle">{{navigationWeekMonth | date:'LLLL, yyyy'}}</div>
        <div [id]="DayEditorOnboardingIdEnum.Fourth">
          <input [matDatepicker]="picker" (dateChange)="setDate($event.value)" [formControl]="selectedDateControl"
                 class="c-dayEditorCalendar__monthInput">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="c-dayEditorCalendar__week">
        <div class="c-dayEditorCalendar__day"
             [id]="isSelectedDay(day.date) ? DayEditorOnboardingIdEnum.Second : null"
             *ngFor="let day of week" [ngClass]="{
              'active': isSelectedDay(day.date),
              'today': isToday(day.date)
             }" (click)="setDate(day.date)">
          <div class="c-dayEditorCalendar__dayName">
            {{day.name | translate}}
          </div>
          <div class="c-dayEditorCalendar__dayNumber">
            {{day.date.getDate()}}
          </div>
        </div>
      </div>
      <div class="c-dayEditorCalendar__nav" [id]="DayEditorOnboardingIdEnum.Third">
        <button class="c-dayEditorCalendar__navBtn" (click)="prevWeek()" matRipple>
          <mat-icon svgIcon="chevron-left"></mat-icon>
        </button>
        <button class="c-dayEditorCalendar__navBtn" (click)="setDate(today)" [matTooltip]="'Jump to today' | translate"
                matRipple>&#8226;
        </button>
        <button class="c-dayEditorCalendar__navBtn" (click)="nextWeek()" matRipple>
          <mat-icon svgIcon="navigate-next"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="c-dayEditor__body">
    <div class="c-dayEditor__phases">
      <div class="c-dayEditor__phasesHeader">
        <p class="c-dayEditor__phasesTitle">{{selectedDate | date:userLocaleService.format.date}}</p>

        <div *ngIf="phases.length" class="flex items-center">

          <ng-container *ngIf="!!priorityPainterEnable else priorityPainterNotEnabled">
            <div class="flex items-center">
              <div class="c-activityListEntry__prioritiesWrapper">
                <div class="flex items-center">
                  <p class="c-activityListEntry__prioritiesTitle c-activityListEntry__prioritiesTitle--itemsCenter">
                    {{'Select priority' | translate}}
                  </p>
                  <ul class="c-activityListEntry__priorities">
                    <li *ngFor="let priority of priorities" (click)="setPriorityPainterIndex(priority)"
                        class="c-activityListEntry__prioritiesItem {{priority.description}}"
                        [ngClass]="{'active': priorityIdByPainterIndex === priority.id}">
                      <span class="c-activityListEntry__prioritiesItemDot"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <app-button btnStyle="outline" class="ml-3.5" (buttonClicked)="changePriorities$.next(false)">
              <span class="btn__text">{{'Confirm changing priorities' | translate}}</span>
            </app-button>
          </ng-container>
          <ng-template #priorityPainterNotEnabled>
            <app-button btnStyle="outline" class="ml-3.5" (buttonClicked)="changePriorities$.next(true)">
              <mat-icon class="btn__icon" svgIcon="edit"></mat-icon>
              <span class="btn__text">{{'Change priorities' | translate}}</span>
            </app-button>
          </ng-template>

          <app-button class="mx-3.5" (buttonClicked)="openPhaseAddDialog()">
            <mat-icon class="btn__icon" svgIcon="add"></mat-icon>
            <span class="btn__text">{{'Add phase' | translate}}</span>
          </app-button>

          <button [id]="DayEditorOnboardingIdEnum.Fifth" [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon svgIcon="more-horiz"></mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="deleteDay()">
              <mat-icon svgIcon="delete"></mat-icon>
              <span>{{'Delete day' | translate}}</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <app-phase-list class="c-dayEditor__phasesList"
                      [priorityPainterIndex]="priorityPainterIndex"
                      [activityStatuses]="activityStatuses"
                      [phasesList]="phases"
                      [priorities]="priorities"
                      [date]="date"
                      [changePriorities$]="changePriorities$"
                      (priorityPainterDataEmitter$)="setPriorityPainterData($event)">
        <ng-container data-phasesEmpty>
          <div class="c-phasesList__empty c-phasesList__empty--outline">
            <mat-icon svgIcon="big-calendar" class="c-phasesList__emptyIcon"></mat-icon>
            <p class="c-phasesList__emptyTitle">{{'You have no phase today' | translate}}</p>
            <p class="c-phasesList__emptyText">{{'Please, add new phase or select template' | translate}}</p>
            <div class="flex items-center justify-center">
              <app-button [id]="DayEditorOnboardingIdEnum.Fifth" btnStyle="outline"
                          (buttonClicked)="openPhaseAddDialog()">
                {{'Add new phase' | translate}}
              </app-button>
              <span class="mx-2 text-gray">{{'or' | translate}}</span>
              <app-button (buttonClicked)="addFromTemplate()">
                {{'Select template' | translate}}
              </app-button>
            </div>
          </div>
        </ng-container>
      </app-phase-list>
    </div>

    <ng-container *ngIf="!!activityMoreInfoData else appointmentsTemplate">
      <div class="c-dayEditor__sidebar">
        <activity-entry-management [activity]="activityMoreInfoData" [priorities]="priorities"></activity-entry-management>
      </div>
    </ng-container>
    <ng-template #appointmentsTemplate>
      <mat-tab-group class="c-dayEditor__sidebar"
                     mat-align-tabs="start"
                     animationDuration="0ms" #rightTabGroup>
        <mat-tab [label]="'Appointments' | translate">
          <app-appointment-list [date]="date"></app-appointment-list>
        </mat-tab>
      </mat-tab-group>
    </ng-template>
  </div>

  <planning-board-clipboard [priorities]="priorities" [parent]="PlanningBoardClipboardParent.DayEditor"></planning-board-clipboard>
</div>

<onboarding-tooltips [onboardingTooltips]="dayEditorOnboardingTooltips"></onboarding-tooltips>
